<template>
  <div class="single-upload-file">
    <el-tooltip :disabled="component.isShowTip" class="item" effect="dark" :content="component.textShowTip" placement="left">
    <el-upload
      class="upload-demo"
      :action="uploadFileUrl"
      :headers="headers"
      :data="component.paramData"
      :limit="1"
      :file-list="component.fileList"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-error="handleError"
      :before-remove="beforeRemove"
      :on-exceed="handleExceed" 
      >
      <div class="up-file-wrapper">
        <div class="up-content ellipsis" @click="event.cancelBubble = true" v-html="component.divShowName">
        </div>
        <div class="up-actions">
          <el-tooltip class="item" effect="dark" content="预览" placement="top-start">
            <el-button size="mini" icon="el-icon-picture-outline" circle @click="previewPicture">
            </el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="选择" placement="top-start">
            <el-button size="mini" icon="el-icon-plus" circle></el-button>
          </el-tooltip>
        </div>
      </div>
    </el-upload>
    </el-tooltip>
    <!-- 图片预览 -->
    <el-image 
      style="width: 100px; height: 100px; display: none;"
      :z-index="2999"
      :src="previewSrc" 
      ref="previewImg"
      :preview-src-list="previewSrcList">
    </el-image>
  </div>
</template>
<script>
export default {
  name: 'SingleFileUpload',
  props: {
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ["doc", "xls", "ppt", "txt", "pdf"],
    },
    /**
     * =====================
     * 常量
     * paramData: {
     *  cosFileName 当前功能文本框name
     *  storagePath 当前文件位置路径 /good/Goods
     *  repeatType: boolean 是否多张上传 Y or N
     * } 文件上传参数
     * fileList: [] 显示文件列表
     * divShowName: '' 文本组件显示文字
     * isShowTip: boolean 是否显示tooltip：false显示 true不显示
     * textShowTip: '' tooltip显示内容
     * previewUrl: '' 预览图初始路径
     * previewUrlList: [] 预览图列表
     */
    constData: {
      type: Object,
      default() {
        return {}
      }
    },
    itemData: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      uploadFileUrl: "http://localhost:8888/system/cos/uploadPicture",
      headers: { // 头部信息
        'X-HODO-Authorization-With': this.getToken()
      }, 
      // 组件信息
      component: {},
      // 当前修改文件
      cfileList: [],
      previewSrc: '',
      previewSrcList: []
    }
  },
  created() {},
  mounted() {
    this.component = this.constData
    this.init();
  },
  watch: {
    constData: {
      handler: function (val, oldVal) {
        if(val) {
          this.component = val
          this.init();
        } 
      },
      deep: true
    },
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    init(){
      if(this.component.fileList.length > 0) {
        // 将form表单中文件路径 赋值给divShowName
        const path = this.component.fileList[0].url
        this.constData.textShowTip = path 
        this.constData.isShowTip = false
        this.constData.previewUrl = path 
        this.constData.previewUrlList[0] = path
        this.previewSrc = this.constData.previewUrl
        this.previewSrcList = this.constData.previewUrlList
      }
    },
    handleSuccess(response, file, fileList) {
      if(!response.success) { // 上传失败
         this.$message({
          type: 'info',
          message: response.message
        })
        // 将上传时存放在fileList中的数据清空
        // 防止赋值给当前div显示的容器
        fileList.length = 0
        return false
      }
      // 上传成功
      // 获取图片完整路径
      const _imgUrl = response.data
      // 将回调的路径返回给父级form表单对应的字段中
      this.cfileList.push({name: response.data, url: response.data})
      this.$emit('fileCallBack', {type: this.itemData, param: this.cfileList})

      // 截取图片名称，将名称赋值给当前divShowName,并赋值给tooltip
      // const index = _imgUrl.lastIndexOf("\/");
      // const _str = _imgUrl.substring(index + 1, _imgUrl.length);
      this.constData.textShowTip = _imgUrl
      this.constData.isShowTip = false // tooltip显示
      // 向预览框赋值图片路径，显示路径
      this.constData.previewUrl = _imgUrl
      this.constData.previewUrlList[0] = _imgUrl
      this.previewSrc = this.constData.previewUrl
      this.previewSrcList = this.constData.previewUrlList
    },
    handleError(err, file, fileList) {
      // console.log(err, file, fileList)
    },
    handleRemove(file, fileList) { // 成功移除图片
      // 删除文件后无需再给tooltip,文本，预览 赋值
        this.constData.isShowTip = true
        this.constData.textShowTip = ''
        // 清空fileList数组
        this.component.fileList.length = 0
        this.cfileList.length = 0
        // 清空预览
        this.previewSrc = ''
        this.previewSrcList = []
    },
    handlePreview(file) {  // 文件上传成功后click钩子触发事件
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`)
    },
    // 图片预览
    previewPicture(event) {
      console.log('preview');
      event.stopPropagation(); // 阻止事件冒泡
      this.previewSrc = this.component.previewUrl
      this.previewSrcList[0] = this.component.previewUrlList[0]
      this.$refs.previewImg.showViewer = true
    }
  },
}
</script>