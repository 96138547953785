<template>
  <div class="editor" ref="editor" :style="styles"></div>
</template>
<script>
  import Quill from "quill";
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import "quill/dist/quill.bubble.css";
  export default {
    name: 'Editor',
    props: {
      /* 编辑器的内容 */
      value: {
        type: String,
        default: "",
      },
      /* 高度 */
      height: {
        type: Number,
        default: null,
      },
      /* 最小高度 */
      minHeight: {
        type: Number,
        default: null,
      },
      /* 所需上传路径参数 */
      editorFileInfo: {
        type: Object,
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        Quill: null,
        currentValue: "",
        options: {
          theme: "snow",
          bounds: document.body,
          debug: "warn",
          modules: {
            // 工具栏配置
            toolbar: [
              ["bold", "italic", "underline", "strike"],       // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"],                    // 引用  代码块
              [{ list: "ordered" }, { list: "bullet" }],       // 有序、无序列表
              [{ indent: "-1" }, { indent: "+1" }],            // 缩进
              [{ size: ["small", false, "large", "huge"] }],   // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }],         // 标题
              [{ color: [] }, { background: [] }],             // 字体颜色、字体背景颜色
              [{ align: [] }],                                 // 对齐方式
              ["clean"],                                       // 清除文本格式
              ["link", "image", "video"]                       // 链接、图片、视频
            ],
          },
          placeholder: "请输入内容",
          readOnly: false,
        },
        imgFiles: {},  // 图片回调成功数据
        editorObj: {}, // 父级文件上传地址信息
      }
    },
    computed: {
      styles() {
        let style = {};
        if (this.minHeight) {
          style.minHeight = `${this.minHeight}px`;
        }
        if (this.height) {
          style.height = `${this.height}px`;
        }
        return style;
      },

    },
    watch: {
      value: {
        handler(val) {
          if (val !== this.currentValue) {
            this.currentValue = val === null ? "" : val;
            if (this.Quill) {
              this.Quill.pasteHTML(this.currentValue);
            }
          }
        },
        immediate: true,
      },
    },
    async mounted() {
      this.editorObj = this.editorFileInfo
      this.init();
    },
    beforeDestroy() {
      this.Quill = null;
    },
    methods: {
      init() {
        const editor = this.$refs.editor;
        this.Quill = new Quill(editor, this.options);
        this.Quill.pasteHTML(this.currentValue);
        this.Quill.on("text-change", (delta, oldDelta, source) => {
          const html = this.$refs.editor.children[0].innerHTML;
          const text = this.Quill.getText();
          const quill = this.Quill;
          this.currentValue = html;
          this.$emit("input", html);
          this.$emit("on-change", { html, text, quill });
        });
        this.Quill.on("text-change", (delta, oldDelta, source) => {
          this.$emit("on-text-change", delta, oldDelta, source);
        });
        this.Quill.on("selection-change", (range, oldRange, source) => {
          this.$emit("on-selection-change", range, oldRange, source);
        });
        this.Quill.on("editor-change", (eventName, ...args) => {
          this.$emit("on-editor-change", eventName, ...args);
        });
        var toolbar = this.Quill.getModule('toolbar');
        toolbar.addHandler('image', this.imageUpload);
      },
      imageUpload(value) {
        const _this1 = this;
        const quill = this.Quill;
        const _editObj = this.editorObj;
        //点击图片，则value值为true
        if(value) {
          //创建隐藏的文件上传input
          const input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.click();
          input.onchange = function() {
            const file = input.files[0];
            //判断文件是否为图片
            if(! /image\/\w+/.test(file.type)) {
              this.$message({ type: 'info', message: '只能选择图片！'})
              return false;
            } else {
              //使用FormData创建键值对数据
              var fd = new FormData();
              fd.append('file', file);
              fd.append('cosFileName', _editObj.cosFileName);  // 文件名称前缀 当前功能文本框name eg: productDesc
              fd.append('repeatType', _editObj.repeatType); //是否支持多图
              fd.append('storagePath', _editObj.storagePath); //存储路径 当前文件路径eg: /good/Goods
              _this1.editorUploader(fd).then(res => {
                this.imgDatas = res
                //取得上传后返回所在的路径
                var imgUrl = res.data;
                //取得图片应该所在的位置
                var currentPosition = quill.getSelection();
                //插入编辑框
                quill.insertEmbed(currentPosition, 'image', imgUrl);
                // 显示成功提示
                console.log('success');
              })
            }
          }
        }
      },
    }
  }
</script>
<style>
.editor, .ql-toolbar {
  white-space: pre-wrap!important;
  line-height: normal !important;
}
.quill-img {
  display: none;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>