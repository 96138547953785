import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/supplierInfo/selectList',
    method: 'get',
    params: query
  })
}

export function addSupplierInfo(data) {
  return request({
    url: '/system/supplierInfo/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateSupplierInfo(data) {
  return request({
    url: '/system/supplierInfo/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delSupplierInfo(configId) {
  return request({
    url: '/system/supplierInfo/deleteByPrimaryKey?supplierId=' + configId,
    method: 'post'
  })
}


export function getSupplierInfo(id) {
  return request({
    url: '/system/supplierInfo/selectByPrimaryKey?supplierId=' + id,
    method: 'post',
  })
}