import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/productInfo/selectList',
    method: 'get',
    params: query
  })
}

export function addGood(data) {
  return request({
    url: '/system/productInfo/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateGood(data) {
  return request({
    url: '/system/productInfo/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delGood(configId) {
  return request({
    url: '/system/productInfo/deleteByPrimaryKey?productId=' + configId,
    method: 'post'
  })
}


export function getGood(id) {
  return request({
    url: '/system/productInfo/selectByPrimaryKey?productId=' + id,
    method: 'post',
  })
}