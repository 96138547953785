// 引入组件
import Vue from 'vue'
import Router from 'vue-router'
// 登录
import Login from '@/views/login/Login'
// 首页
import Index from '@/views/index/Index'

/**
 * 菜单列表
 */
// ---产品管理---
// 产品设置
import Goods from '@/views/good/Goods';
// 产品分类设置
import ProductCategory from '@/views/good/ProductCategory';
// 文件管理
import Files from '@/views/good/Files';
// 参数管理
import Config from '@/views/good/Config';
// 通知公告
import Notice from '@/views/good/Notice';

// ---活动管理---
// 活动设置
import ActivityInfo from '@/views/activity/ActivityInfo';
// 广告设置
import AdInfo from '@/views/activity/AdInfo';
// 优惠卷设置
import CouponInfo from '@/views/activity/CouponInfo';
// 公告设置
import NoticeInfo from '@/views/activity/NoticeInfo';

// ---系统管理---
// 系统参数设置
import SystemPara from '@/views/system/systemPara/SystemPara';
// 常量参数设置
import InitPara from '@/views/system/InitPara';
// 系统用户管理
import SysUser from '@/views/system/sys/SysUser';
// 系统角色管理
import SysRole from '@/views/system/sys/SysRole';

// ---技术人员---
// 技术级别管理
import AgentLevel from '@/views/agent/AgentLevel';
// 技术人员管理
import AgentUser from '@/views/agent/AgentUser';

// ---佣金管理---
// 佣金管理
import CommissionRate from '@/views/commission/CommissionRate';

// ---用户管理---
// 用户管理
import CustomerUser from '@/views/customer/CustomerUser';

// ---订单管理---
// 订单管理
import OrderInfo from '@/views/order/OrderInfo';

// ---供应商管理---
// 供应商管理
import SupplierInfo from '@/views/supplier/SupplierInfo';

// 启用路由
Vue.use(Router)
// 导出路由
export default new Router({
  routes: [
    {
      path: '/',
      name: '登录',
      component: Login
    },
    {
      path: '/login',
      name: '登录',
      component: Login
    },
    {
      path: '/index',
      name: '首页',
      component: Index,
      children: [{
        path: '/good/Goods',
        name: '产品设置',
        component: Goods
      }, {
        path: '/good/ProductCategory',
        name: '产品分类',
        component: ProductCategory
      }, {
        path: '/activity/ActivityInfo',
        name: '活动设置',
        component: ActivityInfo
      }, {
        path: '/activity/AdInfo',
        name: '广告设置',
        component: AdInfo
      }, {
        path: '/activity/CouponInfo',
        name: '优惠卷设置',
        component: CouponInfo
      }, {
        path: '/activity/NoticeInfo',
        name: '公告设置',
        component: NoticeInfo
      }, {
        path: '/good/Files',
        name: '文件管理',
        component: Files
      }, {
        path: '/good/Config',
        name: '参数管理',
        component: Config
      }, {
        path: '/good/Notice',
        name: '通知公告',
        component: Notice
      }, {
        path: '/system/systemPara/SystemPara',
        name: '系统参数设置',
        component: SystemPara
      }, {
        path: '/system/InitPara',
        name: '常量参数设置',
        component: InitPara
      }, {
        path: '/system/sys/SysUser',
        name: '系统用户管理',
        component: SysUser
      }, {
        path: '/system/sys/SysRole',
        name: '系统角色管理',
        component: SysRole
      }, {
        path: '/agent/AgentLevel',
        name: '技术级别管理',
        component: AgentLevel
      }, {
        path: '/agent/AgentUser',
        name: '技术人员管理',
        component: AgentUser
      }, {
        path: '/commission/CommissionRate',
        name: '佣金管理',
        component: CommissionRate
      }, {
        path: '/customer/CustomerUser',
        name: '用户管理',
        component: CustomerUser
      }, {
        path: '/order/OrderInfo',
        name: '订单管理',
        component: OrderInfo
      }, {
        path: '/supplier/SupplierInfo',
        name: '供应商管理',
        component: SupplierInfo
      }]
    }
  ]
})