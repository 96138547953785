import request from '@/network/request'

// 绑定下拉框
// 根据字典类型查询字典数据信息
export function getDicts(dictType, dictKey) {
  return request({
    url: '/system/' + dictType + '/selectAllList',
    method: 'post',
    params: dictKey
  })
}

// editor文件上传
export function editorUploader(data) {
  return request({
    url: '/system/cos/uploadPicture',
    method: 'post',
    data: data
  })
}

// 单文件上传
export function singleUploader(data) {
  return request({
    url: '/system/cos/uploadPicture',
    method: 'post',
    data: data
  })
}

// 多文件上传
export function multipleUploader(data) {
  return request({
    url: '/system/cos/uploadPicture',
    method: 'post',
    data: data
  })
}
