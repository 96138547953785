<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>系统参数设置</a></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="queryParams" class="user-search el-form--inline">
      <el-form-item label="参数代码">
        <el-input size="small" v-model="queryParams.paraCode" auto-complete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="有效标志">
        <el-select size="small" v-model="queryParams.efftFlag" placeholder="请选择">
          <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div class="user-data">
      <!-- 操作按钮 -->
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <el-col :span="1.5">
          <el-button type="primary" @click="handleAdd" plain icon="el-icon-plus" size="mini">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" @click="handleEdit" plain icon="el-icon-edit" :disabled="single" size="mini">修改</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="danger" @click="handleDel" plain icon="el-icon-delete" :disabled="single" size="mini">删除</el-button>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <el-table :data="tableData" stripe border v-loading="loading" element-loading-text="拼命加载中" @selection-change="handleSelectionChange" style="width: 100%;">
        <el-table-column align="center" type="selection" width="60" />
        <el-table-column sortable prop="paraCode" label="参数代码" />
        <el-table-column sortable prop="paraName" label="参数名称" />
        <el-table-column sortable prop="codeLen" label="代码长度" />
        <el-table-column sortable prop="efftFlag" label="有效标志" :formatter="flagFormat" />
        <el-table-column sortable prop="createTime" label="创建时间"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="130">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-edit-outline" @click="handleDetail(scope.row)" style="font-size: 20px;"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <Pagination 
        v-show="total>0" 
        :total="total" 
        :page.sync="queryParams.pageNum" 
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
    <!-- 模态窗口组件 传入参数 -->
    <SystemParaCmpt
      v-if="editInfoObject.show"
      :dialog-show="editInfoObject.show"
      :trans-params="editInfoObject.params"
      @dialogCloseCallback="editCmptCallback"
    />
    <!-- 参数配置详情组件 入参 -->
    <SystemParaDetail 
      v-if="detailInfoObject.show"
      :dialog-show="detailInfoObject.show"
      :trans-params="detailInfoObject.params"
      @dialogCloseCallback="detailCallback"
    />
  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import SystemParaCmpt from './SystemParaCmpt'
import SystemParaDetail from './SystemParaDetail'
import {paraList,delPara,getPara} from '@/network/system/systemPara'
export default {
  name: 'SystemPara',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 表格数据
      tableData: [],
      // 总条数
      total: 0,
      efftFlag: [
        { key: '请选择', value: ''},
        { key: '是', value: 'Y' },
        { key: '否', value: 'N' }
      ],
      // 搜索表单
      queryParams: {
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        paraCode: '',
        efftFlag: 'Y'
      },
      // 编辑参数 模态窗口配置
      editInfoObject: {
        //是否显示弹框
        show: false,
        params: {
          title: '新增',
          modal: {}
        }
      },
      // 详情参数 模态窗口配置
      detailInfoObject: {
        //是否显示弹框
        show: false,
        params: {
          title: '',
          modal: {}
        }
      },
    }
  },
  components: {
    Pagination,
    SystemParaCmpt,
    SystemParaDetail
  },
  created() {
    this.getList()
    this.getDicts('productCategory').then(response => {
      this.categoryOpts = response.data;
    });
    this.getDicts('productInfo').then(response => {
      this.productOpts = response.data;
    });
  },
  methods: {
    // 获取公司列表
    getList() {
      this.loading = true
      paraList(this.queryParams).then(res => {
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    // 搜索事件
    search() {
      this.getList(this.queryParams)
    },
    // 显示弹窗(新增)
    handleAdd() {
      this.editInfoObject.show = true;
      this.editInfoObject.params.modal.formList = {}
    },
    // 显示编辑界面
    handleEdit(index, row) {
       const id = row && row.defineId || this.ids
       getPara(id).then(response => {
        this.editInfoObject.show = true;
        this.editInfoObject.params.title = '修改'
        this.editInfoObject.params.modal = {
          formList: response.data
        }
       })
    },
    // 编辑界面弹窗回调参数
    editCmptCallback(closeParams) {
      if(closeParams.type === 'submit') {
        this.getList(this.queryParams)
      }
      this.editInfoObject.show = false;
    },
    // 删除数据
    handleDel(index, row) {
      const id = row && row.defineId || this.ids;
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPara(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList(this.queryParams)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 参数设置详情
    handleDetail(row) {
      this.detailInfoObject.show = true
      this.detailInfoObject.params.modal = {
        title: row.paraName, 
        paraCode: row.paraCode
      }
    },
    // 详情回调
    detailCallback(closeParams) {
      this.detailInfoObject.show = false;
    },
    // 转换-有效标志
    flagFormat(row, column) {
      return this.selectDictLabel(this.efftFlag, row.efftFlag);
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.defineId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
  }
}
</script>