/**
 * =====================
 * 通用js方法封装处理
 * 2021 ym
 * =====================
 */

// 日期格式化
 export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

// 表单重置
export function resetForm(refName) {
	if (this.$refs[refName]) {
		this.$refs[refName].resetFields();
	}
}

// 回显数据字典
export function selectDictLabel(datas, value) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (String(datas[key].value) === ('' + String(value))) {
			actions.push(datas[key].key);
			return true;
		}
	})
	return actions.join('');
}

// 回显动态数据字典
export function selectDynicLabel(datas, value, dataA) {
	var actions = [];
	var newData = dataA.split(',')
	var _id = newData[0], _name = newData[1]
	Object.keys(datas).some((key) => {
		if (String(datas[key][_id]) === ('' + String(value))) {
			actions.push(datas[key][_name]);
			return true;
		}
	})
	return actions.join('');
}

// 获取字典默认值
export function selectDictDefault(datas) {
	var actions = [];
	Object.keys(datas).some((key) => {
		if (datas[key].is_default === true) {
			actions.push(datas[key].dictValue);
			return true;
		}
	})
  if (!actions[0] && datas[0]) {
    actions.push(datas[0].dictValue)
  }
	return actions.join('');
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
	var actions = [];
	var currentSeparator = undefined === separator ? "," : separator;
	var temp = value.split(currentSeparator);
	Object.keys(value.split(currentSeparator)).some((val) => {
		Object.keys(datas).some((key) => {
			if (datas[key].dictValue == ('' + temp[val])) {
				actions.push(datas[key].dictLabel + currentSeparator);
			}
		})
	})
	return actions.join('').substring(0, actions.join('').length - 1);
}

/**
 * 保存 blob
 * @param  {Blob} blob
 * @param  {String} filename 想要保存的文件名称
 */
 function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    var body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  };
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {cb}
 */
function getBlob(url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
}

/**
 * 通用下载方法
 * 调用getBlob, saveAs
 * @param {*} file_url 
 * @param {*} fileName 
 */
export function download(file_url,fileName) {
  getBlob(process.env.VUE_APP_BASE_API + file_url, function (blob) {
    saveAs(blob, fileName);
  });
}