<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>产品分类</a></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="queryParams" class="user-search el-form--inline">
      <el-form-item label="产品类别">
        <el-select size="small" v-model="queryParams.categoryId" filterable placeholder="请选择">
          <el-option v-for="type in categoryOpts" :label="type.categoryName" :value="type.categoryId" :key="type.categoryId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="有效标志">
        <el-select size="small" v-model="queryParams.efftFlag" placeholder="请选择">
          <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div class="user-data">
      <!-- 操作按钮 -->
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <el-col :span="1.5">
          <el-button type="primary" @click="handleAdd" plain icon="el-icon-plus" size="mini">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" @click="handleEdit" plain icon="el-icon-edit" :disabled="single" size="mini">修改</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="danger" @click="handleDel" plain icon="el-icon-delete" :disabled="single" size="mini">删除</el-button>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <el-table :data="tableData" stripe border v-loading="loading" element-loading-text="拼命加载中" @selection-change="handleSelectionChange" style="width: 100%;">
        <el-table-column align="center" type="selection" width="60" />
        <el-table-column sortable prop="categoryId" label="分类编号"  />
        <el-table-column sortable prop="categoryName" label="分类名称"  />
        <el-table-column sortable prop="categoryKeywords" label="关键词" />
        <el-table-column sortable prop="sortOrder" label="排序" />
        <el-table-column sortable prop="imgUrl" label="分类图片" />
        <el-table-column sortable prop="longImgUrl" label="分类长横图片" />
        <el-table-column sortable prop="iconUrl" label="分类图标" />
        <el-table-column sortable prop="showIndexCategoryType" label="首页分类位置显示" :formatter="showIndexCategoryTypeFormat" />
        <el-table-column sortable prop="showIndexBottomType" label="首页底部位置显示" :formatter="showIndexBottomTypeFormat"/>
        <el-table-column sortable prop="showCategoryType" label="分类是否展示" :formatter="showCategoryTypeFormat"/>
        <el-table-column sortable prop="introduction" label="简介"  />
        <el-table-column sortable prop="efftFlag" label="有效性" :formatter="flagFormat" />
        <el-table-column sortable prop="createTime" label="创建时间" />
        <el-table-column sortable prop="updateTime" label="更新时间" />
      </el-table>
      <!-- 分页组件 -->
      <Pagination 
        v-show="total>0" 
        :total="total" 
        :page.sync="queryParams.pageNum" 
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
      <!-- 编辑页面 -->
      <el-dialog
        width="60%"
        :title="title"
        :visible.sync="open">
        <el-form label-width="180px" :model="editForm" :rules="rules" ref="editForm" class="user-edit el-form--inline">
          <el-form-item label="分类名称" prop="categoryName">
            <el-input size="small" v-model="editForm.categoryName" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="关键词" prop="categoryKeywords">
            <el-input size="small" v-model="editForm.categoryKeywords" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sortOrder" class="form-prefix">
            <el-input size="small" v-model="editForm.sortOrder" auto-complete="off" placeholder="请输入"></el-input>
            <span>号</span>
          </el-form-item>
          <el-form-item label="分类图片" prop="address" class="up-file">
            <SingleFileUpload 
              v-if="prodConstInfo.fileList.length >= 0"
              :const-data="prodConstInfo"
              item-data="imgUrl"
              @fileCallBack="fileCallBackParam"
            />
            <el-input size="small" v-model="editForm.imgUrl" auto-complete="off" class="up-file-input" readonly hidden></el-input>
          </el-form-item>
          <!-- <el-form-item label="分类图片" prop="imgUrl">
            <el-input size="small" v-model="editForm.imgUrl" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item> -->
          <el-form-item label="分类横向长图片" prop="longImgUrl">
            <el-input size="small" v-model="editForm.longImgUrl" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="分类图标url" prop="iconUrl">
            <el-input size="small" v-model="editForm.iconUrl" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="首页分类位置是否展示" prop="showIndexCategoryType">
            <el-select size="small" v-model="editForm.showIndexCategoryType" placeholder="请选择">
              <el-option v-for="type in showIndexCategoryType" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="首页底部位置是否展示" prop="showIndexBottomType">
            <el-select size="small" v-model="editForm.showIndexBottomType" placeholder="请选择">
              <el-option v-for="type in showIndexBottomType" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类是否展示" prop="showCategoryType">
            <el-select size="small" v-model="editForm.showCategoryType" placeholder="请选择">
              <el-option v-for="type in showCategoryType" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="简介" prop="introduction">
            <el-input size="small" v-model="editForm.introduction" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input size="small" v-model="editForm.remarks" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="有效标志" prop="efftFlag">
            <el-select size="small" v-model="editForm.efftFlag" placeholder="请选择">
              <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" :loading="loading" @click="submitForm('editForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 分页组件
import Pagination from '../../components/Pagination'
import SingleFileUpload from '@/components/SingleFileUpload'
import {goodList,addProductCategory,updateProductCategory,delProductCategory,getProductCategory} from '@/network/good/productCategory'
import Editor from '@/components/Editor'
export default {
  name: 'ProductCategory',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 表格数据
      tableData: [],
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 下拉框
      categoryOpts: [],
      efftFlag: [
        { key: '请选择', value: ''},
        { key: '是', value: 'Y' },
        { key: '否', value: 'N' }
      ],
      showIndexCategoryType: [
        { key: '是', value: '1' },
        { key: '否', value: '0' }
      ],
      showIndexBottomType: [
        { key: '是', value: '1' },
        { key: '否', value: '0' }
      ],
      showCategoryType: [
        { key: '是', value: '1' },
        { key: '否', value: '0' }
      ],

      // 搜索表单
      queryParams: {
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        categoryId: '',
        efftFlag: 'Y'
      },
      // 编辑页面表单
      editForm: {},
      // 编辑页面表单校验规则
      rules: {
        categoryName: [{ required: true, message: '请输入分类名称', trigger: 'blur' }],
        imgUrl: [{ required: true, message: '请选择分类图片', trigger: 'blur' }],
        longImgUrl: [{ required: true, message: '请选择分类长横图片', trigger: 'blur' }],
        iconUrl: [{ required: true, message: '请选择分类图标', trigger: 'blur' }],
        efftFlag: [{ required: true, message: '请选择有效性', trigger: 'blur' }]
      },

      // 产品图标文件上传组件
      prodConstInfo: {
        paramData: {
          cosFileName: 'imgUrl', // 当前功能文本框name
          storagePath: '/good/ProductCategory', // 当前文件位置路径 /good/Goods
          repeatType: 'N' // 是否多张上传 
        },
        fileList: [], // 图片列表
        divShowName: '',  //显示文字
        isShowTip: true, // 默认不显示　true不显示 false显示
        textShowTip: '',  // tooltip内容
        previewUrl: '',  // 预览图设置
        previewUrlList: [],  // 预览列表
      },
    }
  },
  // 注册组件
  components: {
    Pagination,
    Editor,
    SingleFileUpload
  },
  // 组件创建完毕
  created() {
    this.getList()
    this.getDicts('productCategory').then(response => {
      this.categoryOpts = response.data;
    });
  },
  // 只有方法被调用才会执行
  methods: {
    // 获取公司列表
    getList() {
      this.loading = true
      goodList(this.queryParams).then(res => {
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    // 搜索事件
    search() {
      this.getList(this.queryParams)
    },


    // 转换-有效标志
    flagFormat(row, column) {
      return this.selectDictLabel(this.efftFlag, row.efftFlag);
    },
    // 转换-首页分类位置是否展示
    showIndexCategoryTypeFormat(row, column) {
      return this.selectDictLabel(this.showIndexCategoryType, row.showIndexCategoryType);
    },
    // 转换-首页底部位置是否展示
    showIndexBottomTypeFormat(row, column) {
      return this.selectDictLabel(this.showIndexBottomType, row.showIndexBottomType);
    },
    // 转换-分类是否展示
    showCategoryTypeFormat(row, column) {
      return this.selectDictLabel(this.showCategoryType, row.showCategoryType);
    },

    //选中的列
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.categoryId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    // 表单重置
    reset() {
      this.editForm = {
        categoryName: '',
        sortOrder: '',
        imgUrl: '',
        longImgUrl: '',
        iconUrl: '',
        showIndexCategoryType: '',
        showIndexBottomType: '',
        showCategoryType: '',
        introduction: '',
        remarks: '',
        efftFlag: ''
      };
      this.resetForm("form");
      // 清空产品图标文件上传所有参数
      this.prodConstInfo = {
        paramData: {
          cosFileName: 'imgUrl', 
          storagePath: '/good/ProductCategory', 
          repeatType: 'N' 
        },
        fileList: [], 
        divShowName: '', 
        isShowTip: true, 
        textShowTip: '', 
        previewUrl: '',  
        previewUrlList: [], 
      }
    },
    // 显示新增页面
    handleAdd() {
      this.reset();
      // 清空upload，preview里的赋值数据
      this.imgUrlName = ''
      this.previewUrl = ''
      this.imgUrlTipdisabled = true
      this.open = true
      this.title = '新增'
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.reset()
      this.open = true
      this.title = '修改'
      const id = row && row.categoryId || this.ids
      getProductCategory(id).then(response => {
        this.editForm = response.data;
        this.open = true;
        this.title = "修改参数";
      });
      this.prodConstInfo.fileList = [] // 清空文件信息 
      getGood(id).then(response => {
        this.editForm = response.data;
        this.prodConstInfo.fileList.push({name: response.data.imgUrl, url: response.data.imgUrl});
        this.prodConstInfo.previewUrl = this.editForm.imgUrl
        this.prodConstInfo.previewUrlList[0] = this.editForm.imgUrl
        this.open = true;
        this.title = "修改参数";
      });
    },
    // 新增，编辑 页面保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          if (this.editForm.categoryId == undefined) {
            addProductCategory(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '保存成功！'
              })
            })
          } else {
            updateProductCategory(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
            })
          }
        } else {
          return false
        }
      })
    },
    // 关闭编辑页面
    closeDialog() {
      this.open = false
    },
    // 删除数据
    handleDel(index, row) {
      const id = row && row.categoryId || this.ids;
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delProductCategory(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList(this.queryParams)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
  }
}
</script>