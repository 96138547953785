import request from '@/network/request'

// 登入
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    idValueC: code,
    idKeyC: uuid
  }
  return request({
    url: '/system/author/login',
    method: 'post',
    data: data
  })
}

// 登出
export function logout() {
  return request({
    url: '/system/author/logout',
    method: 'post'
  })
}