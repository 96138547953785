import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/orderInfo/selectList',
    method: 'get',
    params: query
  })
}

export function addOrderInfo(data) {
  return request({
    url: '/system/orderInfo/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateOrderInfo(data) {
  return request({
    url: '/system/orderInfo/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delOrderInfo(configId) {
  return request({
    url: '/system/orderInfo/deleteByPrimaryKey?orderId=' + configId,
    method: 'post'
  })
}


export function getOrderInfo(id) {
  return request({
    url: '/system/orderInfo/selectByPrimaryKey?orderId=' + id,
    method: 'post',
  })
}