<template>
  <div>
    <el-container>
      <el-aside>
        <!-- LOGO -->
        <div class="menu-bar">
          <a class="logo" href="#">
            <span>yixindoc</span>
            <!-- <span>com</span> -->
            <i class="glyphicon glyphicon-leaf"></i>
          </a>
        </div>
        <!-- ! LOGO -->
        <!-- sidebar start -->
        <el-menu
          class="el-menu-vertical-demo"
          background-color="#36404e"
          text-color="#fff"
          active-text-color="#409EFF"
          :unique-opened="true"
          router>
          <el-submenu v-for="item in menuList" :index="item.id" :key="item.id">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{item.title}}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item v-for="citem in item.menuChild" :index="'/'+citem.url" :key="citem.cid" @click="menuClick(citem)">
                <i :class="citem.icon"></i>
                {{citem.title}}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <!-- ! sidebar end -->
      </el-aside>
      <!-- RightBox Start -->
      <el-container>
        <!-- header start -->
        <el-header>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
            <el-submenu index="1">
              <template slot="title">超级管理员</template>
              <el-menu-item index="1-1">..</el-menu-item>
              <el-menu-item index="1-2" @click.native="exit()">退出</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-header>
        <!-- ! header end -->
        <!-- main Start -->
        <el-main>
            <!-- content start -->
            <!--  <router-view :key="key"/> -->
            <router-view/>
            <h1 style="text-align: center;" v-if="hintInfoShow">(♥◠‿◠)ﾉﾞ  欢迎您的到来！   ლ(´ڡ`ლ)ﾞ ﾞ ﾞ</h1>
            <!-- ! content end -->
        </el-main>
        <!-- ! main End -->

      </el-container>
      <!-- ! RightBox End -->
    </el-container>
  </div>
</template>

<script>
// import SidebarMenu from '../../components/menu/SidebarMenu'

import {logout} from '@/network/login'
import {getToken, removeToken} from '@/utils/auth'
import {getMainMultidata} from '@/network/index'

export default {
  name: 'Index',
  data() {
    return {
      menuList: [],
      msg: 'success',
      activeIndex: '1',
      eq: "", // 当前选中菜单的下标
      navUrl: ["/index"], // 路由地址列表
      hintInfoShow: true,
    }
  },
  // 创建完毕状态（操作）
  created() {
    let res = {
      data: [{
        id: '1',
        title: '产品管理',
        icon: 'el-icon-s-goods',
        url: null,
        menuChild: [{
          cid: '11',
          title: '产品设置',
          icon: 'el-icon-files',
          url: 'good/Goods'
        }, {
          cid: '12',
          title: '分类设置',
          icon: 'el-icon-document',
          url: 'good/ProductCategory'
        }, {
          cid: '13',
          title: '配置设置',
          icon: 'el-icon-document',
          url: 'good/Config'
        }, {
          cid: '14',
          title: '属性设置',
          icon: 'el-icon-s-cooperation',
          url: 'good/files'
        }, {
          cid: '15',
          title: '详情设置',
          icon: 'el-icon-s-cooperation',
          url: 'good/Notice'
        }, {
          cid: '16',
          title: '轮播图设置',
          icon: 'el-icon-s-cooperation',
          url: 'good/Advertisement'
        }]
      }, {
        id: '2',
        title: '活动管理',
        icon: 'el-icon-box',
        url: null,
        menuChild: [{
          cid: '23',
          title: '活动设置',
          icon: '',
          url: 'activity/ActivityInfo'
        }, {
          cid: '24',
          title: '广告设置',
          icon: '',
          url: 'activity/AdInfo'
        }, {
          cid: '25',
          title: '优惠卷设置',
          icon: '',
          url: 'activity/CouponInfo'
        }, {
          cid: '26',
          title: '公告设置',
          icon: '',
          url: 'activity/NoticeInfo'
        }]
      }, {
        id: '3',
        title: '系统管理',
        icon: 'el-icon-box',
        url: null,
        menuChild: [{
          cid: '33',
          title: '系统参数设置',
          icon: 'el-icon-setting',
          url: 'system/systemPara/SystemPara'
        }, {
          cid: '34',
          title: '常量参数设置',
          icon: 'el-icon-setting',
          url: 'system/InitPara'
        }, {
          cid: '35',
          title: '系统用户管理',
          icon: 'el-icon-setting',
          url: 'system/sys/SysUser'
        }, {
          cid: '36',
          title: '系统角色管理',
          icon: 'el-icon-setting',
          url: 'system/sys/SysRole'
        }]
      }, {
        id: '4',
        title: '技术人员',
        icon: 'el-icon-box',
        url: null,
        menuChild: [{
          cid: '41',
          title: '技术级别管理',
          icon: 'el-icon-setting',
          url: 'agent/AgentLevel'
        }, {
          cid: '42',
          title: '技术人员管理',
          icon: 'el-icon-setting',
          url: 'agent/AgentUser'
        }]
      }, {
        id: '5',
        title: '佣金管理',
        icon: 'el-icon-box',
        url: null,
        menuChild: [{
          cid: '51',
          title: '佣金设置',
          icon: 'el-icon-setting',
          url: 'commission/CommissionRate'
        }]
      }, {
        id: '6',
        title: '用户管理',
        icon: 'el-icon-box',
        url: null,
        menuChild: [{
          cid: '61',
          title: '用户管理',
          icon: 'el-icon-setting',
          url: 'customer/CustomerUser'
        }]
      }, {
        id: '7',
        title: '订单管理',
        icon: 'el-icon-box',
        url: null,
        menuChild: [{
          cid: '71',
          title: '订单管理',
          icon: 'el-icon-setting',
          url: 'order/OrderInfo'
        }]
      }, {
        id: '8',
        title: '供应商管理',
        icon: 'el-icon-box',
        url: null,
        menuChild: [{
          cid: '81',
          title: '供应商管理',
          icon: 'el-icon-setting',
          url: 'supplier/SupplierInfo'
        }]
      }],
    }

    this.menuList = res.data
    this.initHint();
    
    // 监听
    // this.$root.Bus.$on('toggle', value => {
    //   console.log(124);
    //   this.collapsed = !value
    // })
  },
  watch: {
    eq: {
      handler: function (val, oldVal) {
        if(val) {
          this.eq = val
          this.initHint()
        } 
      },
      deep: true
    },
  },
  components: {
    // SidebarMenu
  },
  // 计算属性
  computed: {
    key() {
    // return '/' + this.$route.path.split('/').reverse()[0];
      return this.$route.fullPath;
    }
  },
  methods: {
    initHint() {
      !this.eq ? this.eq = 0 : this.eq; //若this.eq 不存在则设置默认值为 0
      if(this.eq === 0 && this.$route.path !== "/index") {
          this.$router.push({path: '/index'})//设置路由默认地址
          this.hintInfoShow = true
      } else if(this.$route.path === "/index") {
        this.hintInfoShow = true
      } else {
        this.hintInfoShow = false
      }
      console.log(this.hintInfoShow);
    },
    menuClick(val) {
      this.eq = val //存储下标
     // this.$router.push({path: val.path})
      //bus.$emit('sendTitle', val.path)
    },
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        setTimeout(() => {
          logout().then((res) => {
            this.$store.commit('login', 'false')
            this.$router.push({ path: res.data.url })
            removeToken()
            this.$message({
              type: 'success',
              message: '已退出登录!'
            })
          })
        }, 1000)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
      
    }
  }
}
</script>
