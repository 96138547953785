import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/agentLevel/selectList',
    method: 'get',
    params: query
  })
}

export function addAgentLevel(data) {
  return request({
    url: '/system/agentLevel/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateAgentLevel(data) {
  return request({
    url: '/system/agentLevel/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delAgentLevel(configId) {
  return request({
    url: '/system/agentLevel/deleteByPrimaryKey?levelId=' + configId,
    method: 'post'
  })
}


export function getAgentLevel(id) {
  return request({
    url: '/system/agentLevel/selectByPrimaryKey?levelId=' + id,
    method: 'post',
  })
}