import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/couponInfo/selectList',
    method: 'get',
    params: query
  })
}

export function addCouponInfo(data) {
  return request({
    url: '/system/couponInfo/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateCouponInfo(data) {
  return request({
    url: '/system/couponInfo/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delCouponInfo(configId) {
  return request({
    url: '/system/couponInfo/deleteByPrimaryKey?couponId=' + configId,
    method: 'post'
  })
}

export function getCouponInfo(id) {
  return request({
    url: '/system/couponInfo/selectByPrimaryKey?couponId=' + id,
    method: 'post',
  })
}