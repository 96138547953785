import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/commissionRate/selectList',
    method: 'get',
    params: query
  })
}

export function addCommissionRate(data) {
  return request({
    url: '/system/commissionRate/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateCommissionRate(data) {
  return request({
    url: '/system/commissionRate/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delCommissionRate(configId) {
  return request({
    url: '/system/commissionRate/deleteByPrimaryKey?commissionId=' + configId,
    method: 'post'
  })
}


export function getCommissionRate(id) {
  return request({
    url: '/system/commissionRate/selectByPrimaryKey?commissionId=' + id,
    method: 'post',
  })
}