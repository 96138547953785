<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>通知公告</a></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="queryParams" ref="queryForm" class="user-search el-form--inline">
      <el-form-item label="公告标题" prop="title">
        <el-input v-model="queryParams.title" placeholder="公告标题" clearable size="small" @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="公告类型" prop="message_type">
        <el-select v-model="queryParams.message_type" placeholder="公告类型" clearable size="small">
          <el-option 
            v-for="dict in MsgTypeOpt" 
            :label="dict.dictLabel"
            :value="dict.dictValue" 
            :key="dict.dictValue"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="公告状态" prop="status">
        <el-select v-model="queryParams.status" placeholder="公告状态" clearable size="small">
          <el-option
            v-for="item in MsgStatusOpt" 
            :label="item.dictLabel" 
            :value="item.dictValue" 
            :key="item.dictValue"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="user-data">
      <!-- 操作按钮 -->
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <el-col :span="1.5">
          <el-button 
            type="primary" 
            plain 
            icon="el-icon-plus" 
            size="mini" 
            @click="handleAdd" 
          >发布公告
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
          >修改公告
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
          >删除公告
          </el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table v-loading="loading" :data="dataList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="公告主键" align="center" prop="id" />
        <el-table-column
          label="公告标题"
          align="center"
          prop="title"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="公告内容"
          align="center"
          prop="content"
          :formatter="typeFormat"
        />
        <el-table-column
          label="公告类型"
          align="center"
          prop="message_type"
          :formatter="statusFormat"
        />
        <el-table-column label="公告状态" align="center" prop="status" :formatter="statusFormat"/>
        <el-table-column label="跳转路径" align="center" prop="to_path" :show-overflow-tooltip="true"/>
        <el-table-column label="创建时间" align="center" prop="create_datetime" width="100">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.create_datetime, '{y}-{m}-{d}') }}</span>
          </template>
        </el-table-column>
      </el-table>
      <Pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
    </div>
    <!-- 对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="780px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="公告标题" prop="title">
              <el-input v-model="form.title" placeholder="请输入公告标题"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跳转路径" prop="to_path">
              <el-input v-model="form.to_path" placeholder="请输入跳转路径"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-radio-group v-model="form.status">
                <el-radio
                  v-for="dict in MsgStatusOpt"
                  :key="dict.dictValue"
                  :label="dict.dictValue"
                >{{dict.dictLabel}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公告类型" prop="message_type">
              <el-select v-model="form.message_type" placeholder="请选择">
                <el-option
                  v-for="dict in MsgTypeOpt"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="公告内容" prop="content">
              <editor v-model="form.content" :min-height="192"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" :loading="loading" @click="submitForm">确 定</el-button>
        <el-button size="small" @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { listNotice, getNotice, delNotice, addNotice, updateNotice, exportNotice, getDicts } from "@/network/good/notice";
  import Pagination from '@/components/Pagination'
  import Editor from '@/components/Editor';
  export default {
    name: 'notice',
    data() {
      return {
        // 遮罩层
        loading: true,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 表格数据
        dataList: [],
        // 总条数
        total: 0,
        // 弹出层标题
        title: "",
        // 是否显示弹出层
        open: false,
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          noticeTitle: undefined,
          createBy: undefined,
          status: undefined
        },
        // 类型下拉框
        MsgTypeOpt: [],
        // 状态下拉框
        MsgStatusOpt: [],
        // 表单参数
        form: {},
        // 表单校验
        rules: {
          title: [
            { required: true, message: "公告标题不能为空", trigger: "blur" }
          ],
          content: [
            {required: true, message: "公告内容不能为空", trigger: "blur"}
          ],
          to_path: [
            {required: false, message: "跳转路径不能为空", trigger: "blur"}
          ]
        }
      }
    },
    components: {
      Pagination,
      Editor
    },
    created() {
      this.getList();
      getDicts("sys_message_push_type").then(response => {
        this.MsgTypeOpt = response.data;
      });
      getDicts("sys_message_push_status").then(response => {
        this.MsgStatusOpt = response.data;
      });
    },
    methods: {
      // 查询列表
      getList() {
        this.loading = true;
        listNotice(this.queryParams).then(response => {
          this.dataList = response.data.results;
          this.total = response.data.count;
          this.loading = false;
        })
      },
      /** 搜索按钮操作 */
      handleQuery() {
        this.queryParams.pageNum = 1;
        this.getList();
      },
      // 重置按钮
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 发布公告
      handleAdd() {
        this.reset();
        this.open = true;
        this.title = "发布公告";
      },
      // 修改公告
      handleUpdate(row) {
        this.reset();
        const id = row.id || this.ids
        getNotice(id).then(response => {
          this.form = response.data;
          this.open = true;
          this.title = "修改公告";
        });
      },
      // 删除公告
      handleDelete(row) {
        const configIds = row.id || this.ids;
        this.$confirm('是否确认删除公告编号为"' + configIds + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delMessage(configIds);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
      },
      // 提交表单
      submitForm() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            if (this.form.id != undefined) {
              updateNptice(this.form).then(response => {
                this.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              });
            } else {
              addNotice(this.form).then(response => {
                this.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              });
            }
          }
        });
      },
      // 关闭弹框
      cancel() {
        this.open = false;
        this.reset();
      },
      // 表单重置
      reset() {
        this.form = {
          id: undefined,
          title: undefined,
          content: undefined,
          to_path: undefined,
          is_reviewed: true,
          message_type: this.selectDictDefault(this.MsgStatusOpt),
          status: this.selectDictDefault(this.MsgTypeOpt),
        };
        this.resetForm("form");
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length !== 1
        this.multiple = !selection.length
      },
      // 公告状态转换
      statusFormat(row, column) {
        return this.selectDictLabel(this.MsgStatusOpt, row.status);
      },
      // 公告类型转换
      typeFormat(row, column) {
        return this.selectDictLabel(this.MsgTypeOpt, row.message_type);
      },
    }
  }
</script>
<style>

</style>