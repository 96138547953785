<template>
  <!-- 系统参数详情配置弹窗 -->
  <el-dialog width="70%" :visible="propsDialogShow" :title="dialogTitle" @close="dialogClose()">
    <!-- 操作按钮 -->
    <el-row class="el-table-toolbar mb8" :gutter="10">
      <el-col :span="1.5">
        <el-button type="primary" @click="handleAdd" plain icon="el-icon-plus" size="mini">新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" @click="handleEdit" plain icon="el-icon-edit" :disabled="single" size="mini">修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" @click="handleDel" plain icon="el-icon-delete" :disabled="single" size="mini">删除</el-button>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table :data="tableData" stripe border v-loading="loading" element-loading-text="拼命加载中" @selection-change="handleSelectionChange" style="width: 100%;">
      <el-table-column align="center" type="selection" width="60" />
      <el-table-column sortable prop="paraCode" label="参数代码" />
      <el-table-column sortable prop="codeNo" label="明细代码" />
      <el-table-column sortable prop="codeName" label="明细内容" />
      <el-table-column sortable prop="efftFlag" label="有效标志" :formatter="flagFormat" />
      <el-table-column sortable prop="createTime" label="创建时间"/>
    </el-table>
    <!-- 分页组件 -->
    <Pagination 
      v-show="total>0" 
      :total="total" 
      :page.sync="queryParams.pageNum" 
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
    <!-- 编辑dialog -->
    <el-dialog width="60%" :title="detailDialogTitle" :visible.sync="detailDialogShow" :append-to-body="appendBody">
      <el-form label-width="180px" :model="formList" :rules="rules" ref="editForm" class="user-edit el-form--inline">
        <el-form-item label="显示序号" prop="serialNo">
          <el-input size="small" v-model="formList.serialNo" auto-complete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="明细代码" prop="codeNo">
          <el-input size="small" v-model="formList.codeNo" auto-complete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="明细内容" prop="paraName">
          <el-input size="small" v-model="formList.codeName" auto-complete="off" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="有效标志" prop="efftFlag">
          <el-select size="small" v-model="formList.efftFlag" placeholder="请选择">
            <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="formList.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="detailDialogClose">取 消</el-button>
        <el-button size="small" type="primary" @click="detailSubmitForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
import {paraListDetail,addParaDetail,updateParaDetail,delParaDetail,getParaDetail} from '@/network/system/systemPara'
export default {
  name: 'SystemParaDetail',
  props: {
    // 模态窗口显示标识
    dialogShow: Boolean,
    // 外部参数传递
    transParams: Object
  },
  components: {
    Pagination,
  },
  data() {
    return {
      // 模态窗口显示标识
      propsDialogShow: true,
      // 表单title(从外部接收参数)
      dialogTitle: "",
      // 规定当前弹窗是否添加至body元素
      appendBody: true,
      // 选中数组
      ids: [],
      // 遮罩层
      loading: true,
      // 列表操作集
      queryParams: {
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        paraCode: '',
        efftFlag: 'Y'
      },
      total: 0,
      // 非单个禁用
      single: true,
      // 列表数据
      tableData: [],
      efftFlag: [
        { key: '请选择', value: ''},
        { key: '是', value: 'Y' },
        { key: '否', value: 'N' }
      ],
      // 编辑详情弹窗显示标识
      detailDialogShow: false,
      // 详情弹窗标题
      detailDialogTitle: '新增',
      // 操作表单信息集
      formList: {},
      // 编辑页面表单校验规则
      rules: {
        serialNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        codeNo: [{ required: true, message: '请输入', trigger: 'blur' }],
        codeName: [{ required: true, message: '请输入', trigger: 'blur' }],
        efftFlag: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
    }
  },
  created() {
    this.propsDialogShow = this.dialogShow
    this.dialogTitle = this.transParams.modal.title;
    this.queryParams.paraCode = this.transParams.modal.paraCode
    this.getList()
  },
  methods: {
    // 获取详情列表
    getList() {
      this.loading = true
      paraListDetail(this.queryParams).then(res => {
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    // 新增
    handleAdd() {
      this.detailDialogShow = true;
      this.formList = {}
    },
    // 修改
    handleEdit(index, row) {
      const id = row && row.detailId || this.ids
      getParaDetail(id).then(response => {
        this.detailDialogShow = true;
        this.detailDialogTitle = '修改'
        this.formList = response.data
      })
    },
    // 提交
    detailSubmitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          if(this.formList.detailId === undefined) {
            this.formList['paraCode'] = this.queryParams.paraCode
            addParaDetail(this.formList).then(res => {
              this.$message({
                type: 'success',
                message: '保存成功！'
              })
              this.detailDialogShow = false;
              this.getList(this.queryParams)
            }) 
          } else {
            updateParaDetail(this.formList).then(res => {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.detailDialogShow = false;
              this.getList(this.queryParams)
            })
          }
        } else {
          return false
        }
      })
    },
    // 删除
    handleDel(index, row) {
      const id = row && row.detailId || this.ids;
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delParaDetail(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList(this.queryParams)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 详情设置窗口关闭
    detailDialogClose() {
      this.detailDialogShow = false
    },
    // 弹窗回调
    dialogCloseCallback() {
      
    },
    // 模态窗口关闭
    dialogClose () {
      this.propsDialogShow = false;
      this.$emit("dialogCloseCallback", {
        type: "cancel"
      });
    },
    // 转换-有效标志
    flagFormat(row, column) {
      return this.selectDictLabel(this.efftFlag, row.efftFlag);
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.detailId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    }
  },
}
</script>