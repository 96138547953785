<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination 
        :background="background" 
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :layout="layout" 
        :page-sizes="pageSizes"
        :total="total"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"> 
    </el-pagination>
  </div>
</template>
<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'Pageiation',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  // 计算属性
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
  // props: ['childMsg'],
  // data() {
  //   return {
  //     pageparm: {
  //       currentPage: this.childMsg.currentPage,
  //       pageSize: this.childMsg.pageSize
  //     },
  //     pageSizes: [10, 20, 30, 40]
  //   }
  // },
  // created() {},
  // methods: {
  //   handleSizeChange(val) {
  //     /**
  //      * 子传父
  //      * 参数1 父元素方法
  //      * 参数2 数据
  //      */
  //     this.pageparm.pageSize = val
  //     this.$emit('callFather', this.pageparm)
  //   },
  //   handleCurrentChange(val) {
  //     this.pageparm.currentPage = val
  //     this.$emit('callFather', this.pageparm)
  //   }
  // }
}
</script>

<style>
  .pagination-container {
    position: relative;
    background: #fff;
    height: 52px;
    padding: 20px 16px;
  }
  .pagination-container.hidden {
    display: none;
  }
</style>