import request from '@/network/request'

export function paraList(query) {
  return request({
    url: '/system/paraDefine/selectList',
    method: 'get',
    params: query
  })
}

export function addPara(data) {
  return request({
    url: '/system/paraDefine/insertSelective',
    method: 'post',
    data: data
  })
}

export function updatePara(data) {
  return request({
    url: '/system/paraDefine/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delPara(configId) {
  return request({
    url: '/system/paraDefine/deleteByPrimaryKey?defineId=' + configId,
    method: 'post'
  })
}

export function getPara(id) {
  return request({
    url: '/system/paraDefine/selectByPrimaryKey?defineId=' + id,
    method: 'post',
  })
}

// 系统参数详情配置
export function paraListDetail(query) {
  return request({
    url: '/system/paraDetail/selectList',
    method: 'get',
    params: query
  })
}

export function addParaDetail(data) {
  return request({
    url: '/system/paraDetail/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateParaDetail(data) {
  return request({
    url: '/system/paraDetail/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delParaDetail(configId) {
  return request({
    url: '/system/paraDetail/deleteByPrimaryKey?detailId=' + configId,
    method: 'post'
  })
}

export function getParaDetail(id) {
  return request({
    url: '/system/paraDetail/selectByPrimaryKey?detailId=' + id,
    method: 'post',
  })
}
