<template>
  <div class="login-wrap">
    <el-form ref="logForm" :model="logForm" :rules="rules" label-width="0px" class="demo-ruleForm login-container">
        <h3 class="title">{{title}}</h3>
        <el-form-item prop="username">
          <el-input type="text" v-model="logForm.username" autocomplete="off" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="logForm.password" autocomplete="off" placeholder="密码"></el-input>
        </el-form-item>
        <el-checkbox v-model="logForm.rememberMe" style="margin: 0px 0px 25px !important;">记住密码</el-checkbox>
        <el-form-item>
          <el-button type="primary" @click="submitForm('logForm')" :loading="logining" style="width: 100%!important;">登录</el-button>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
import {login} from '@/network/login'
import {decrypt, encrypt} from '@/utils/jsencrypt'

export default {
  name: 'Login',
  data () {
    return {
      title: '我家口腔管理系统',
      logining: false,  //loading默认为false
      storagePassword: '',
      logForm: {
        username: '',
        password: '',
        rememberMe: false
      },
      rules: {  // rules前端验证
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  created() {   // 创建完毕状态(里面是操作)
    // this.$message({
    //   message: '账号及密码不为空即可',
    //   type: 'success'
    // })
    this.getLocalStorage(this.logForm);
  },
  mounted() {
     window.addEventListener('keydown',this.keyDown);
  },
  methods: {
    keyDown(e){
        //如果是回车则执行登录方法
      if(e.keyCode == 13){
        this.submitForm('logForm')
      }
    },
    getLocalStorage() {
      const info = JSON.parse("" + localStorage.getItem('loguinfo') + "");
      if(info !== null) {
        this.logForm = {
          username: info.username === undefined ? this.logForm.username : info.username,
          password: info.password === undefined ? this.logForm.password : decrypt(info.password),
          rememberMe: info.rememberMe === undefined ? false : Boolean(info.rememberMe),
        };
      }
    },
    submitForm(formName) {
      this.$refs[`${formName}`].validate(valid => {
        if (valid) {
          this.logining = true
          login(this.logForm.username, this.logForm.password, this.logForm.code, this.logForm.uuid).then(res => {
            if(this.logForm.rememberMe) {
              const info = {
                username: this.logForm.username,
                password: encrypt(this.logForm.password),
                rememberMe: this.logForm.rememberMe
              }
              localStorage.setItem('loguinfo', JSON.stringify(info));
            } else {
              localStorage.removeItem('loguinfo');
            }
            //如果请求成功就让他2秒跳转路由
            setTimeout(() => {
              this.logining = false
              // 缓存token
              localStorage.setItem('token', res.data.toeknValue)
              // 缓存用户个人信息
              // localStorage.setItem('userdata', JSON.stringify(res.data))
              // store保存数据: commit
              // store获取变量：this.$store.state.变量名
              this.$store.commit('login', 'true')  
              // 路由跳转页面
              this.$router.push({ path: res.data.url })
              this.$message({
                message: '登录成功',
                type: 'success'
              })
            }, 1000)
          }).catch(() => {
            setTimeout(() => {
              this.logining = false;
            }, 1000)
          })
        }

      })
    }
  }
}
</script>
<style>
  .login-wrap {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 10%;
    /* background-position: center right;*/
    background-image: url("https://hodo-1302958707.cos.ap-shanghai.myqcloud.com/loveteeth/back-login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-wrap .login-container {
    border-radius: 10px;
    margin: 0px auto;
    width: 400px;
    padding: 30px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    text-align: left;
    -webkit-box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
    box-shadow: 0 0 20px 2px rgb(0 0 0 / 10%);
  } 

  .login-wrap .title {
    margin: 0px auto 40px auto;
    text-align: center;
    color: #505458;
  }

  .login-wrap .el-form-item__content {
    margin-left: 0px !important;
  }
</style>