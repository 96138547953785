import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/activityInfo/selectList',
    method: 'get',
    params: query
  })
}

export function addActivityInfo(data) {
  return request({
    url: '/system/activityInfo/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateActivityInfo(data) {
  return request({
    url: '/system/activityInfo/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delActivityInfo(configId) {
  return request({
    url: '/system/activityInfo/deleteByPrimaryKey?activityId=' + configId,
    method: 'post'
  })
}


export function getActivityInfo(id) {
  return request({
    url: '/system/activityInfo/selectByPrimaryKey?activityId=' + id,
    method: 'post',
  })
}