import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/noticeInfo/selectList',
    method: 'get',
    params: query
  })
}

export function addNoticeInfo(data) {
  return request({
    url: '/system/noticeInfo/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateNoticeInfo(data) {
  return request({
    url: '/system/noticeInfo/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delNoticeInfo(configId) {
  return request({
    url: '/system/noticeInfo/deleteByPrimaryKey?noticeId=' + configId,
    method: 'post'
  })
}

export function getNoticeInfo(id) {
  return request({
    url: '/system/noticeInfo/selectByPrimaryKey?noticeId=' + id,
    method: 'post',
  })
}