import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/agentUser/selectList',
    method: 'get',
    params: query
  })
}

export function addAgentUser(data) {
  return request({
    url: '/system/agentUser/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateAgentUser(data) {
  return request({
    url: '/system/agentUser/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delAgentUser(configId) {
  return request({
    url: '/system/agentUser/deleteByPrimaryKey?agentId=' + configId,
    method: 'post'
  })
}


export function getAgentUser(id) {
  return request({
    url: '/system/agentUser/selectByPrimaryKey?agentId=' + id,
    method: 'post',
  })
}