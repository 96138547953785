<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>参数设置</a></el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" class="user-search">
      <el-form-item label="参数名称" prop="configName">
        <el-input v-model="queryParams.configName" @keyup.enter.native="handleQuery" placeholder="请输入参数名称" size="small" clearable/>
      </el-form-item>
      <el-form-item label="参数键名" prop="configKey">
        <el-input v-model="queryParams.configKey" @keyup.enter.native="handleQuery" placeholder="请输入参数键名" size="small" clearable/>
      </el-form-item>
      <el-form-item label="系统内置" prop="configType">
        <el-select v-model="queryParams.configType" placeholder="请选择系统内置" size="small" clearable >
          <el-option v-for="item in configTypeOpt" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="user-data">
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-plus" size="mini">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-edit" size="mini">修改</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="danger" plain icon="el-icon-delete" size="mini">删除</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="warning" plain icon="el-icon-download" size="mini">导出</el-button>
        </el-col>
      </el-row>
      <el-table v-loading="loading" :data="dataList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column label="参数主键" align="center" prop="id" />
        <el-table-column label="参数名称" align="center" prop="configName" :show-overflow-tooltip="true" />
        <el-table-column label="参数键名" align="center" prop="configKey" :show-overflow-tooltip="true" />
        <el-table-column label="参数键值" align="center" prop="configValue" />
        <el-table-column label="系统内置" align="center" prop="configType" :formatter="typeFormat" />
        <el-table-column label="状态" align="center" prop="status" :formatter="statusFormat" />
        <el-table-column label="备注" align="center" prop="remark" :show-overflow-tooltip="true" />
        <el-table-column label="创建时间" align="center" prop="create_datetime" width="180">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.create_datetime) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import {listConfig, getConfig, getConfigKey, addConfig, updateConfig, delConfig, exportConfig, getDicts} from '@/network/good/config'

  export default {
    name: 'Config',
    data() {
      return {
        loading: true,
        ids: [],
        single: true,
        multiple: true,
        showSearch: true,
        total: 0,
        // 参数表格数据
        dataList: [],
        title: "",
        open: false,
        // 系统内置
        configTypeOpt: [],
        // 状态数据
        statusOpt: [],
        // 查询参数
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          configName: undefined,
          configKey: undefined,
          configType: undefined,
          status: undefined
        },
        // 表单参数
        form: {},
        rules: {
          configName: [
            { required: true, message: "参数名称不能为空", trigger: "blur" }
          ],
          configKey: [
            { required: true, message: "参数键名不能为空", trigger: "blur" }
          ],
          configValue: [
            { required: true, message: "参数键值不能为空", trigger: "blur" }
          ]
        }
      }
    },
    created() {
      this.getList()
      getDicts("sys_yes_no").then(response => {
        this.configTypeOpt = response.data
      })
      getDicts("sys_normal_disable").then(response => {
        this.configTypeOpt = response.data
      })
    },
    methods: {
      getList() {
        this.loading = true;
        listConfig(this.queryParams).then(response => {
          this.dataList = response.data.results;
          this.total = response.data.count;
          this.loading = false;
        })
      },
      handleQuery() {

      },
      resetQuery() {

      },
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length !== 1
        this.multiple = !selection.length
      },
      typeFormat(row, column) {

        return this.selectDictLabel(this.configTypeOpt, row.configType);
      },
      statusFormat(row, column) {
        return this.selectDictLabel(this.statusOpt, row.status);
      },
    }
  }
</script>