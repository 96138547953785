import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/sysRole/selectList',
    method: 'get',
    params: query
  })
}

export function addSysRole(data) {
  return request({
    url: '/system/sysRole/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateSysRole(data) {
  return request({
    url: '/system/sysRole/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delSysRole(configId) {
  return request({
    url: '/system/sysRole/deleteByPrimaryKey?roleId=' + configId,
    method: 'post'
  })
}


export function getSysRole(id) {
  return request({
    url: '/system/sysRole/selectByPrimaryKey?roleId=' + id,
    method: 'post',
  })
}