<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>佣金设置</a></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="queryParams" class="user-search el-form--inline">
      <!-- <el-form-item label="供应商">
        <el-select size="small" v-model="queryParams.supplierId" filterable placeholder="请选择">
          <el-option v-for="type in categoryOpts" :label="type.categoryName" :value="type.commissionId" :key="type.commissionId"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="有效标志">
        <el-select size="small" v-model="queryParams.efftFlag" placeholder="请选择">
          <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div class="user-data">
      <!-- 操作按钮 -->
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <el-col :span="1.5">
          <el-button type="primary" @click="handleAdd" plain icon="el-icon-plus" size="mini">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" @click="handleEdit" plain icon="el-icon-edit" :disabled="single" size="mini">修改</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="danger" @click="handleDel" plain icon="el-icon-delete" :disabled="single" size="mini">删除</el-button>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <el-table :data="tableData" stripe border v-loading="loading" element-loading-text="拼命加载中" @selection-change="handleSelectionChange" style="width: 100%;">
        <el-table-column align="center" type="selection" width="60" />
        <el-table-column sortable prop="commissionId" label="佣金编号"  />
        <el-table-column sortable prop="supplierId" label="供应商编号"  />
        <el-table-column sortable prop="platformPercent" label="平台佣金" />
        <el-table-column sortable prop="supplierPercent" label="供应商佣金" />
        <el-table-column sortable prop="referencesPercent" label="推荐人佣金" />
        <el-table-column sortable prop="defaultStarPercent" label="默认评价佣金" />
        <el-table-column sortable prop="oneStarPercent" label="一星评价佣金" />
        <el-table-column sortable prop="twoStarPercent" label="二星评价佣金" />
        <el-table-column sortable prop="threeStarPercent" label="三星评价佣金" />
        <el-table-column sortable prop="fourStarPercent" label="四星评价佣金" />
        <el-table-column sortable prop="fiveStarPercent" label="五星评价佣金" />
        <el-table-column sortable prop="otherPercent" label="其他佣金" />
        <el-table-column sortable prop="description" label="描述"  />
        <el-table-column sortable prop="efftFlag" label="有效性" :formatter="flagFormat" />
      </el-table>
      <!-- 分页组件 -->
      <Pagination 
        v-show="total>0" 
        :total="total" 
        :page.sync="queryParams.pageNum" 
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
      <!-- 编辑页面 -->
      <el-dialog
        width="60%"
        :title="title"
        :visible.sync="open">
        <el-form label-width="180px" :model="editForm" :rules="rules" ref="editForm" class="user-edit el-form--inline">
          <el-form-item label="供应商编号" prop="supplierId">
            <el-input size="small" v-model="editForm.supplierId" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="平台佣金" prop="platformPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.platformPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="供应商佣金" prop="supplierPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.supplierPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="推荐人佣金" prop="referencesPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.referencesPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="默认评价佣金" prop="defaultStarPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.defaultStarPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="一星评价佣金" prop="oneStarPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.oneStarPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="二星评价佣金" prop="twoStarPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.twoStarPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="三星评价佣金" prop="threeStarPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.threeStarPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="四星评价佣金" prop="fourStarPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.fourStarPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="五星评价佣金" prop="fiveStarPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.fiveStarPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="其他佣金" prop="otherPercent" class="form-prefix">
            <el-input size="small" v-model="editForm.otherPercent" auto-complete="off" placeholder="请输入"></el-input>
            <span>%</span>
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input size="small" v-model="editForm.description" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="有效标志" prop="efftFlag">
            <el-select size="small" v-model="editForm.efftFlag" placeholder="请选择">
              <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" :loading="loading" @click="submitForm('editForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 分页组件
import Pagination from '../../components/Pagination'
import {goodList,addCommissionRate,updateCommissionRate,delCommissionRate,getCommissionRate} from '@/network/commission/commissionRate'
import Editor from '@/components/Editor'
export default {
  name: 'CommissionRate',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 表格数据
      tableData: [],
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,

      efftFlag: [
        { key: '请选择', value: ''},
        { key: '是', value: 'Y' },
        { key: '否', value: 'N' }
      ],

      // 搜索表单
      queryParams: {
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        // supplierId: '',
        efftFlag: 'Y'
      },
      // 编辑页面表单
      editForm: {},
      // 编辑页面表单校验规则
      rules: {
        supplierId: [{ required: true, message: '请选择供应商', trigger: 'blur' }],
        platformPercent: [{ required: true, message: '请输入平台佣金', trigger: 'blur' }],
        supplierPercent: [{ required: true, message: '请输入供应商佣金', trigger: 'blur' }],
        referencesPercent: [{ required: true, message: '请输入推荐人佣金', trigger: 'blur' }],
        defaultStarPercent: [{ required: true, message: '请输入默认评价佣金', trigger: 'blur' }],
        oneStarPercent: [{ required: true, message: '请输入一星评价佣金', trigger: 'blur' }],
        twoStarPercent: [{ required: true, message: '请输入二星评价佣金', trigger: 'blur' }],
        threeStarPercent: [{ required: true, message: '请输入三星评价佣金', trigger: 'blur' }],
        fourStarPercent: [{ required: true, message: '请输入四星评价佣金', trigger: 'blur' }],
        fiveStarPercent: [{ required: true, message: '请输入五星评价佣金', trigger: 'blur' }],
        otherPercent: [{ required: true, message: '请输入其他佣金', trigger: 'blur' }],
        efftFlag: [{ required: true, message: '请选择有效性', trigger: 'blur' }]
      }
    }
  },
  // 注册组件
  components: {
    Pagination,
    Editor
  },
  // 组件创建完毕
  created() {
    this.getList()
  },
  // 只有方法被调用才会执行
  methods: {
    // 获取公司列表
    getList() {
      this.loading = true
      goodList(this.queryParams).then(res => {
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    // 搜索事件
    search() {
      this.getList(this.queryParams)
    },


    // 转换-有效标志
    flagFormat(row, column) {
      return this.selectDictLabel(this.efftFlag, row.efftFlag);
    },  

    //选中的列
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.commissionId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    // 表单重置
    reset() {
      this.editForm = {
        supplierId: '',
        platformPercent: '',
        supplierPercent: '',
        referencesPercent: '',
        defaultStarPercent: '',
        oneStarPercent: '',
        twoStarPercent: '',
        threeStarPercent: '',
        fourStarPercent: '',
        fiveStarPercent: '',
        otherPercent: '',
        description: '',
        efftFlag: ''
      };
      this.resetForm("form");
    },
    // 显示新增页面
    handleAdd() {
      this.reset();
      this.open = true
      this.title = '新增'
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.reset()
      this.open = true
      this.title = '修改'
      const id = row && row.commissionId || this.ids
      getCommissionRate(id).then(response => {
        this.editForm = response.data;
        this.open = true;
        this.title = "修改参数";
      });
    },
    // 新增，编辑 页面保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          if (this.editForm.commissionId == undefined) {
            addCommissionRate(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '保存成功！'
              })
            })
          } else {
            updateCommissionRate(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
            })
          }
        } else {
          return false
        }
      })
    },
    // 关闭编辑页面
    closeDialog() {
      this.open = false
    },
    // 删除数据
    handleDel(index, row) {
      const id = row && row.commissionId || this.ids;
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCommissionRate(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList(this.queryParams)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
  }
}
</script>