<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>产品设置</a></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="queryParams" class="user-search el-form--inline">
      <el-form-item label="产品类别">
        <el-select size="small" v-model="queryParams.categoryId" @change="selCtChangeConfig" filterable placeholder="请选择">
          <el-option v-for="type in categoryOpts" :label="type.categoryName" :value="type.categoryId" :key="type.categoryId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品名称">
        <el-select size="small" v-model="queryParams.productId" filterable placeholder="请选择">
          <el-option v-for="type in productOpts" :label="type.productName" :value="type.productId" :key="type.productId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否销售">
        <el-select size="small" v-model="queryParams.saleStatus" placeholder="请选择">
          <el-option v-for="type in saleStatus" :label="type.key" :value="type.value" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker size="small" v-model="queryParams.createTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
        -
        <el-date-picker size="small" v-model="queryParams.endcreateTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-date-picker v-model="queryParams.startUpdateTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
        -
        <el-date-picker v-model="queryParams.endUpdateTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="有效标志">
        <el-select size="small" v-model="queryParams.efftFlag" placeholder="请选择">
          <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div class="user-data">
      <!-- 操作按钮 -->
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <el-col :span="1.5">
          <el-button type="primary" @click="handleAdd" plain icon="el-icon-plus" size="mini">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" @click="handleEdit" plain icon="el-icon-edit" :disabled="single" size="mini">修改</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="danger" @click="handleDel" plain icon="el-icon-delete" :disabled="single" size="mini">删除</el-button>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <el-table :data="tableData" stripe border v-loading="loading" element-loading-text="拼命加载中" @selection-change="handleSelectionChange" style="width: 100%;">
        <el-table-column align="center" type="selection" width="60" />
        <el-table-column sortable prop="categoryId" label="产品分类" :formatter="categoryFormat" />
        <el-table-column sortable prop="productName" label="产品名" />
        <el-table-column sortable prop="introduction" label="产品简介" />
        <el-table-column sortable prop="imgUrl" label="产品图标" show-overflow-tooltip />
        <el-table-column sortable prop="efftFlag" label="有效标志" :formatter="flagFormat" />
        <el-table-column sortable prop="priceRange" label="产品显示价格区间" />
        <el-table-column sortable prop="productKeywords" label="搜索关键词" />
        <el-table-column sortable prop="saleStatus" label="是否销售" :formatter="statusFormat" />
        <el-table-column sortable prop="sellPrice" label="产品价格(元)" />
        <el-table-column sortable prop="showCategoryType" label="分类展示" :formatter="categoryTypeFormat" />
        <el-table-column sortable prop="showIndexBottomType" label="首页底部分类展示" :formatter="bottomTypeFormat" />
        <el-table-column sortable prop="showIndexProductType" label="首页产品位置展示" :formatter="productTypeFormat" />
        <el-table-column sortable prop="productDesc" label="产品详情" show-overflow-tooltip />
      </el-table>
      <!-- 分页组件 -->
      <Pagination 
        v-show="total>0" 
        :total="total" 
        :page.sync="queryParams.pageNum" 
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
      <!-- 编辑页面 -->
      <el-dialog
        width="60%"
        :title="title"
        :visible.sync="open">
        <el-form label-width="180px" :model="editForm" :rules="rules" ref="editForm" class="user-edit el-form--inline">
          <el-form-item label="产品分类" prop="categoryId">
            <el-select size="small" v-model="editForm.categoryId" filterable placeholder="请选择">
              <el-option v-for="type in categoryOpts" :label="type.categoryName" :value="type.categoryId" :key="type.categoryId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品名称" prop="productName">
            <el-input size="small" v-model="editForm.productName" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="产品图标url" prop="address" class="up-file">
            <SingleFileUpload 
              v-if="prodConstInfo.fileList.length >= 0"
              :const-data="prodConstInfo"
              item-data="imgUrl"
              @fileCallBack="fileCallBackParam"
            />
            <el-input size="small" v-model="editForm.imgUrl" auto-complete="off" class="up-file-input" readonly hidden></el-input>
          </el-form-item>
          <el-form-item label="有效标志" prop="efftFlag">
            <el-select size="small" v-model="editForm.efftFlag" placeholder="请选择">
              <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品显示价格区间" prop="priceRange" class="form-prefix">
            <el-input size="small" v-model="editForm.priceRange" auto-complete="off" placeholder="请输入"></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item label="搜索关键词" prop="productKeywords">
            <el-input size="small" v-model="editForm.productKeywords" auto-complete="off" class="up-file-input" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="是否销售" prop="saleStatus">
            <el-select size="small" v-model="editForm.saleStatus" placeholder="请选择">
              <el-option v-for="type in saleStatus" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品价格" prop="sellPrice" class="form-prefix">
            <el-input size="small" v-model="editForm.sellPrice" auto-complete="off" placeholder="请输入"></el-input>
            <span>元</span>
          </el-form-item>
          <el-form-item label="分类是否展示" prop="showCategoryType">
            <el-select size="small" v-model="editForm.showCategoryType" placeholder="请选择">
              <el-option v-for="type in showCategoryType" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="首页底部分类是否展示" prop="showIndexBottomType">
            <el-select size="small" v-model="editForm.showIndexBottomType" placeholder="请选择">
              <el-option v-for="type in showIndexBottomType" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="首页产品位置是否展示" prop="showIndexProductType">
            <el-select size="small" v-model="editForm.showIndexProductType" placeholder="请选择">
              <el-option v-for="type in showIndexProductType" :label="type.key" :value="type.value" :key="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品简介" prop="introduction">
            <el-input type="textarea" v-model="editForm.introduction"></el-input>
          </el-form-item>
          <el-form-item label="产品详情" prop="productDesc" class="form-editor">
            <editor 
              v-model="editForm.productDesc"
              :editor-file-info="imgUploaderInfo"
              :min-height="192"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" :loading="loading" @click="submitForm('editForm')">确 定</el-button>
        </div>
      </el-dialog>
      
    </div>
    
  </div>
</template>
<script>
// 分页组件
import Pagination from '@/components/Pagination'
import SingleFileUpload from '@/components/SingleFileUpload'
import {goodList,addGood,updateGood,delGood,getGood} from '@/network/good/goods'
import Editor from '@/components/Editor'
export default {
  name: 'Goods',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 表格数据
      tableData: [],
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 下拉框
      categoryOpts: [],
      productOpts: [],

      efftFlag: [
        { key: '请选择', value: ''},
        { key: '是', value: 'Y' },
        { key: '否', value: 'N' }
      ],
      saleStatus: [
        { key: '请选择', value: ''},
        { key: '是', value: '1' },
        { key: '否', value: '0' }
      ],
      showCategoryType: [
        { key: '请选择', value: ''},
        { key: '是', value: '1' },
        { key: '否', value: '0' }
      ],
      showIndexBottomType: [
        { key: '请选择', value: ''},
        { key: '是', value: '1' },
        { key: '否', value: '0' }
      ],
      showIndexProductType: [
        { key: '请选择', value: ''},
        { key: '是', value: '1' },
        { key: '否', value: '0' }
      ],
      // 分类-产品 联动参数
      linkId: {},
      // 搜索表单
      queryParams: {
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        categoryId: '',
        productId: '',
        saleStatus: '1',
        createTime: null,
        endcreateTime: null,
        startUpdateTime: null,
        endUpdateTime: null,
        efftFlag: 'Y'
      },
      // 编辑页面表单
      editForm: {},
      // 编辑页面表单校验规则
      rules: {
        categoryId: [{ required: true, message: '请输入分类', trigger: 'blur' }],
        productName: [{ required: true, message: '请输入产品名', trigger: 'blur' }],
        sellPrice: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        saleStatus: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      // 编辑器文件上传所需参数
      imgUploaderInfo: {
        cosFileName: 'productDesc', // 当前功能文本框name
        storagePath: '/good/Goods', // 当前文件位置路径 /good/Goods
        repeatType: 'Y' // 是否多张上传
      }, 
      // 产品图标文件上传组件
      prodConstInfo: {
        paramData: {
          cosFileName: 'imgUrl', // 当前功能文本框name
          storagePath: '/good/Goods', // 当前文件位置路径 /good/Goods
          repeatType: 'N' // 是否多张上传 
        },
        fileList: [], // 图片列表
        divShowName: '',  //显示文字
        isShowTip: true, // 默认不显示　true不显示 false显示
        textShowTip: '',  // tooltip内容
        previewUrl: '',  // 预览图设置
        previewUrlList: [],  // 预览列表
      },
    }
  },
  // 注册组件
  components: {
    Pagination,
    Editor,
    SingleFileUpload
  },
  // 组件创建完毕
  created() {
    this.getList()
    this.getDicts('productCategory').then(response => {
      this.categoryOpts = response.data;
    });
    this.getDicts('productInfo').then(response => {
      this.productOpts = response.data;
    });
  },
  // 只有方法被调用才会执行
  methods: {
    // 获取公司列表
    getList() {
      this.loading = true
      goodList(this.queryParams).then(res => {
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    // 搜索事件
    search() {
      this.getList(this.queryParams)
    },
    // 改变产品分类value值,实现联动,给下拉框绑定change事件
    selCtChangeConfig(val) {
      this.productOpts = []; // 将被联动下拉置空
      this.queryParams.productId = null, // 将被选下拉值置空
      this.linkId = {
        categoryId: this.queryParams.categoryId
      }
      this.getDicts('productInfo', this.linkId).then(response => {
        this.productOpts = response.data;
      });
    },
    // 转换-产品分类
    categoryFormat(row, column) {
      const dataA = 'categoryId,categoryName';
      return this.selectDynicLabel(this.categoryOpts, row.categoryId, dataA)
    },
    // 转换-有效标志
    flagFormat(row, column) {
      return this.selectDictLabel(this.efftFlag, row.efftFlag);
    },
    // 转换-是否销售
    statusFormat(row, column) {
      return this.selectDictLabel(this.saleStatus, row.saleStatus);
    },
    // 转换-分类展示
    categoryTypeFormat(row, column) {
      return this.selectDictLabel(this.showCategoryType, row.showCategoryType);
    },
    // 转换-底部分类展示
    bottomTypeFormat(row, column) {
      return this.selectDictLabel(this.showIndexBottomType, row.showIndexBottomType);
    },
    // 转换- 产品位置展示
    productTypeFormat(row, column) {
      return this.selectDictLabel(this.showIndexProductType, row.showIndexProductType);
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.productId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    // 表单重置
    reset() {
      this.editForm = {
        categoryId: '',
        productName: '',
        imgUrl: '',
        efftFlag: '',
        priceRange: '',
        productKeywords: '',
        saleStatus: '',
        sellPrice: '',
        showCategoryType: '',
        showIndexBottomType: '',
        showIndexProductType: '',
        productDesc: '',
        introduction: ''
      };
      this.resetForm("form");
      // 清空产品图标文件上传所有参数
      this.prodConstInfo = {
        paramData: {
          cosFileName: 'imgUrl', 
          storagePath: '/good/Goods', 
          repeatType: 'N' 
        },
        fileList: [], 
        divShowName: '', 
        isShowTip: true, 
        textShowTip: '', 
        previewUrl: '',  
        previewUrlList: [], 
      }
    },
    // 显示新增页面
    handleAdd() {
      this.reset();
      // 清空upload，preview里的赋值数据
      this.imgUrlName = ''
      this.previewUrl = ''
      this.imgUrlTipdisabled = true
      this.open = true
      this.title = '新增'
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.reset()
      this.imgUrlTipdisabled = false
      this.open = true
      this.title = '修改'
      const id = row && row.productId || this.ids
      this.prodConstInfo.fileList = [] // 清空文件信息 
      getGood(id).then(response => {
        this.editForm = response.data;
        this.prodConstInfo.fileList.push({name: response.data.imgUrl, url: response.data.imgUrl});
        this.prodConstInfo.previewUrl = this.editForm.imgUrl
        this.prodConstInfo.previewUrlList[0] = this.editForm.imgUrl
        this.open = true;
        this.title = "修改参数";
      });
    },
    // 新增，编辑 页面保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          if (this.editForm.productId == undefined) {
            addGood(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '保存成功！'
              })
            })
          } else {
            updateGood(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
            })
          }
        } else {
          return false
        }
      })
    },
    // 关闭编辑页面
    closeDialog() {
      this.open = false
    },
    // 删除数据
    handleDel(index, row) {
      const id = row && row.productId || this.ids;
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delGood(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList(this.queryParams)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 文件上传回调
    fileCallBackParam(params) {
      const _type = params.type
      const _file = params.param
      this.editForm[_type] = _file[0].url
      console.log(this.editForm);
    },
  }
}
</script>