<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>产品分类</a></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="queryParams" class="user-search el-form--inline">
      <el-form-item label="订单类型">
        <el-select size="small" v-model="queryParams.orderStatusFormat" filterable placeholder="请选择">
          <el-option v-for="type in orderStatusFormat" :label="type.key" :value="type.value" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="search">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 列表 -->
    <div class="user-data">
      <!-- 操作按钮 -->
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <!-- <el-col :span="1.5">
          <el-button type="primary" @click="handleAdd" plain icon="el-icon-plus" size="mini">新增</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" @click="handleEdit" plain icon="el-icon-edit" :disabled="single" size="mini">修改</el-button>
        </el-col> -->
        <el-col :span="1.5">
          <el-button type="danger" @click="handleDel" plain icon="el-icon-delete" :disabled="single" size="mini">删除</el-button>
        </el-col>
      </el-row>
      <!-- 列表 -->
      <el-table :data="tableData" stripe border v-loading="loading" element-loading-text="拼命加载中" @selection-change="handleSelectionChange" style="width: 100%;">
        <el-table-column align="center" type="selection" width="60" />
        <el-table-column sortable prop="orderId" label="订单编号"  />
        <el-table-column sortable prop="customerId" label="下单人"  />
        <el-table-column sortable prop="productName" label="产品名称" />
        <el-table-column sortable prop="consigneeName" label="收货人" />
        <el-table-column sortable prop="consigneeMobile" label="收货人手机号" />
        <el-table-column sortable prop="consigneeRegion" label="客户所在区域" />
        <el-table-column sortable prop="consigneeAddress" label="客户所在地址" />
        <el-table-column sortable prop="consigneeDetailAddress" label="客户所在详细地址" />
        <el-table-column sortable prop="remarks" label="订单备注" />
        <el-table-column sortable prop="advancePayPrice" label="预付款价格" />
        <el-table-column sortable prop="restPayPrice" label="尾款价格" />
        <el-table-column sortable prop="agentId" label="服务人员id" />
        <el-table-column sortable prop="orderStatus" label="订单状态" :formatter="orderStatusFormat" />
        <el-table-column sortable prop="advancePayTime" label="预付款付款时间" />
        <el-table-column sortable prop="restPayTime" label="尾款付款时间" />
        <el-table-column sortable prop="acceptTime" label="接单时间" />
        <el-table-column sortable prop="serviceTime" label="服务时间" />
        <el-table-column sortable prop="finishTime" label="完成时间" />
      </el-table>
      <!-- 分页组件 -->
      <Pagination 
        v-show="total>0" 
        :total="total" 
        :page.sync="queryParams.pageNum" 
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
      <!-- 编辑页面 -->
      <el-dialog
        width="60%"
        :title="title"
        :visible.sync="open">
        <el-form label-width="180px" :model="editForm" :rules="rules" ref="editForm" class="user-edit el-form--inline">
          <el-form-item label="下单人" prop="customerId">
            <el-input size="small" v-model="editForm.customerId" auto-complete="off" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="closeDialog">取 消</el-button>
          <el-button size="small" type="primary" :loading="loading" @click="submitForm('editForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// 分页组件
import Pagination from '../../components/Pagination'
import {goodList,addOrderInfo,updateOrderInfo,delOrderInfo,getOrderInfo} from '@/network/order/orderInfo'
import Editor from '@/components/Editor'
export default {
  name: 'OrderInfo',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 表格数据
      tableData: [],
      // 总条数
      total: 0,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,

      //订单状态
      orderStatus: [
        { key: '请选择', value: ''},
        { key: '待付款', value: '101' },
        { key: '待接单', value: '201' },
        { key: '待服务', value: '301' },
        { key: '服务中', value: '401' },
        { key: '已完成', value: '501' }
      ],

      // 搜索表单
      queryParams: {
        limit: 10,
        pageNum: 1,
        pageSize: 10,
        orderStatus: ''
      },
      // 编辑页面表单
      editForm: {},
      // 编辑页面表单校验规则
      rules: {
        customerId: [{ required: true, message: '下单人员', trigger: 'blur' }]
      }
    }
  },
  // 注册组件
  components: {
    Pagination,
    Editor
  },
  // 组件创建完毕
  created() {
    this.getList()
  },
  // 只有方法被调用才会执行
  methods: {
    // 获取公司列表
    getList() {
      this.loading = true
      goodList(this.queryParams).then(res => {
        this.tableData = res.rows
        this.total = res.total
        this.loading = false
      })
    },
    // 搜索事件
    search() {
      this.getList(this.queryParams)
    },


    // 转换-订单状态
    orderStatusFormat(row, column) {
      return this.selectDictLabel(this.orderStatus, row.orderStatus);
    },


    //选中的列
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.orderId)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    // 表单重置
    reset() {
      this.editForm = {
        customerId: ''
      };
      this.resetForm("form");
    },
    // 显示新增页面
    handleAdd() {
      this.reset();
      this.open = true
      this.title = '新增'
    },
    // 显示编辑界面
    handleEdit(index, row) {
      this.reset()
      this.open = true
      this.title = '修改'
      const id = row && row.orderId || this.ids
      getOrderInfo(id).then(response => {
        this.editForm = response.data;
        this.open = true;
        this.title = "修改参数";
      });
    },
    // 新增，编辑 页面保存
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          if (this.editForm.orderId == undefined) {
            addOrderInfo(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '保存成功！'
              })
            })
          } else {
            updateOrderInfo(this.editForm).then(res => {
              this.open = false
              this.loading = false
              this.getList(this.queryParams)
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
            })
          }
        } else {
          return false
        }
      })
    },
    // 关闭编辑页面
    closeDialog() {
      this.open = false
    },
    // 删除数据
    handleDel(index, row) {
      const id = row && row.orderId || this.ids;
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delOrderInfo(id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getList(this.queryParams)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
  }
}
</script>