// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'

// 引入element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//引入全局样式
import "./assets/css/base.css";

// 引入路由
import router from './router';

// 引入状态管理
import store from './store/store';

// 引入公共axioss
import axios from 'axios';
Vue.prototype.$axios = axios;

import {getToken} from "@/utils/auth"

import {
  getDicts,
  editorUploader,
  singleUploader,
  multipleUploader,
} from "@/utils/dict";

import {
  parseTime,
  resetForm,
  selectDictLabel,
  selectDynicLabel,
  selectDictDefault,
  selectDictLabels,
  download,
} from "@/utils/public";

// 全局方法挂载
Vue.prototype.getToken = getToken
Vue.prototype.getDicts = getDicts
Vue.prototype.editorUploader = editorUploader
Vue.prototype.singleUploader = singleUploader
Vue.prototype.multipleUploader = multipleUploader
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDynicLabel = selectDynicLabel
Vue.prototype.selectDictDefault = selectDictDefault
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
// Vue.prototype.hasPermi = function (values) {
//   const permissions = store.getters && store.getters.permissions
//   return permissions.some(permission => {
//     return "*:*:*" === permission || values.includes(permission)
//   })
// };

Vue.prototype.msgSuccess = function (msg) {
  this.$message({showClose: true, message: msg, type: "success"});
}

Vue.prototype.msgError = function (msg) {
  this.$message({showClose: true, message: msg, type: "error"});
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}

// 使用element UI
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,   //使用store vuex状态管理
  render: h => h(App)
}).$mount("#app")




