import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/sysUser/selectList',
    method: 'get',
    params: query
  })
}

export function addSysUser(data) {
  return request({
    url: '/system/sysUser/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateSysUser(data) {
  return request({
    url: '/system/sysUser/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delSysUser(configId) {
  return request({
    url: '/system/sysUser/deleteByPrimaryKey?sysUserId=' + configId,
    method: 'post'
  })
}


export function getSysUser(id) {
  return request({
    url: '/system/sysUser/selectByPrimaryKey?sysUserId=' + id,
    method: 'post',
  })
}