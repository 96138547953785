<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item><a>文件设置</a></el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="queryParams" ref="queryForm" class="user-search el-form--inline">
      <el-form-item label="文件名称" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入文件名称" clearable size="small" @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="文件类型" prop="type">
        <el-input v-model="queryParams.type" placeholder="请输入文件类型" clearable size="small" @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- content -->
    <div class="user-data">
      <!-- 操作按钮 -->
      <el-row class="el-table-toolbar mb8" :gutter="10">
        <el-col :span="1.5">
          <el-button
            type="primary"
            plain
            icon="el-icon-upload"
            size="mini"
            @click="handleAdd">
            文件上传
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
          >批量删除
          </el-button>
        </el-col>
        <!-- <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar> -->
      </el-row>
      <!-- 列表 -->
      <el-table v-loading="loading" :data="dataList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="文件编号" width="90" align="center" prop="id"/>
        <el-table-column label="文件名称" width="200" align="center" prop="name" :show-overflow-tooltip="true"/>
        <el-table-column label="文件类型" width="150" align="center" prop="type"/>
        <el-table-column label="文件大小" width="90" align="center" prop="size"/>
        <el-table-column label="存储位置" align="center" prop="address"/>
        <el-table-column label="文件来源" align="center" prop="source"/>
        <el-table-column label="文件本地地址" align="center" prop="file" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-document-copy" @click="CopyFileUrl(scope.row.file)" v-if="scope.row.file"></el-button>
            &nbsp;
            <span>{{ scope.row.file }}</span>
          </template>
        </el-table-column>
        <el-table-column label="OSS地址" align="center" prop="oss_url" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-document-copy" @click="CopyFileUrl(scope.row.oss_url)" v-if="scope.row.oss_url"></el-button>
            &nbsp;
            <span>{{ scope.row.oss_url }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="create_datetime" width="160">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.create_datetime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="130">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-download" @click="handleDownload(scope.row)">
              下载
            </el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <Pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList"/>
    </div>
    <!-- 对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="400px" append-to-body @close="submitForm">
      <FileUpload ref="saveFile" :file-type="['ALL']" @input="submitForm"/>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm">完 成</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import Pagination from '../../components/Pagination'
  import FileUpload from '../../components/FileUpload'
  import {listFiles, delFile} from '@/network/good/files';

  export default {
    name: 'Files',
    data() {
      return {
        loading: true,   // 遮罩层
        single: true,    // 非单个禁用
        multiple: true,  // 非多个禁用
        title: "",       // 弹出层标题
        open: false,     // 是否显示弹出层
        queryParams: {   // 查询参数
          pageNum: 1,
          pageSize: 10,
          name: undefined,
          type: undefined
        },
        total: 0,        // 总条数
        dataList: [],    // 数据列表
      }
    },
    components: {
      Pagination,
      FileUpload
    },
    created() {
      this.getList()
    },
    methods: {
      // 查询列表
      getList() {
        this.loading = true;
        listFiles(this.queryParams).then(response => {
          this.dataList = response.data.results;
          this.total = response.data.count;
          this.loading = false;
        })
      },
      // 搜索按钮
      handleQuery() {
        this.getList();
      },
      // 重置按钮
      resetQuery() {
        this.resetForm("queryForm");
        this.handleQuery();
      },
      // 上传
      handleAdd(row) {
        this.open = true;
        this.title = "上传文件";
      },
      // 删除操作
      handleDelete(row) {
        const userIds = row.id || this.ids;
        this.$confirm('是否确认删除文件编号为"' + userIds + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delFile(userIds);
        }).then(() => {
          this.getList();
          this.msgSuccess("删除成功");
        })
      },
      // 文件下载
      handleDownload(row) {
        this.download(row.file_url, row.name)
      },
      // 完成按钮
      submitForm: function () {
        this.getList();
        this.open = false;
        this.$refs.saveFile.fileList = []
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.id)
        this.single = selection.length !== 1
        this.multiple = !selection.length
      },
      // 复制文本
      CopyFileUrl(values){
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', values);
        input.select();
        if (document.execCommand('copy')) {
          document.execCommand('copy');
          this.msgSuccess("复制成功");
        }
        document.body.removeChild(input);
      }
    }
  }
</script>