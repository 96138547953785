import axios from 'axios'
import {Notification, MessageBox, Message} from 'element-ui'
import router from '../router'

import {getToken, removeToken} from '@/utils/auth'
import errCode from '@/utils/errCode'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default function request(config) {
  const instance = axios.create({
    baseURL: 'https://back.yixindoc.com/',  // https://api.django-vue-admin.com  http://localhost:8888/ http://zhenxingchina.natapp1.cc
    timeout: 5000
  })

  // import axios from 'axios';

  // axios({
  //   url: 'http://123.207.32.32:8000/home/multidata',
  //   method: 'get'
  // }).then(res => {
  //   console.log(res);
  // })

  // axios({
  //   url: 'http://123.207.32.32:8000/home/data',
  //   params: {  // 请求参数
  //     type: 'pop', 
  //     page: 1
  //   }
  // }).then(res => {
  //   console.log(res);
  // })

  // // axios发送并发请求
  // axios.all([axios({
  //   url: 'http://123.207.32.32:8000/home/multidata'
  // }), axios({
  //   url: 'http://123.207.32.32:8000/home/data',
  //   params: {  // 请求参数
  //     type: 'pop',
  //     page: 1
  //   }
  // })]).then(results => {
  //     console.log(results);
  // })

  //2.axios的拦截器
  //2.1.请求拦截的作用
  instance.interceptors.request.use(config => {
    //1.比如config中的一些信息不符合服务器的要求
    //2.每次发送网络请求时，都希望在界面中显示一个请求的图标
    //3.某些网络请求（比如登录（token）），必须携带一些特殊信息

    // 是否需要设置token
    // config.headers.Authorization = localStorage.getItem('logintoken')
    // 非登录请求的场合，判断token是否存在
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
      config.headers['X-HODO-Authorization-With'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    } else {
      // token不存在
      removeToken();
      router.push('/login');
    }
    // get请求映射参数
    if(config.method === 'get' && config.params) {
      let url = config.url + '?';
      for(const name of Object.keys(config.params)) {
        const value = config.params[name];
        const part = encodeURIComponent(name) + "=";
        if(value !== null && typeof(value) !== "undefined") {
          if(typeof value === 'object') {
            for(const key of Object.keys(value)) {
              let params = name + '[' + key + ']';
              const subPart = encodeURIComponent(params) + "=";
              url += subPart + encodeURIComponent(value[key]) + "&";
            }
          } else {
            url += part + encodeURIComponent(value) + "&";
          }
        }
      }
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    } 
    return config
  }, err => {
    console.log(err);
  })

  //2.2.响应拦截
  instance.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errCode[code] || res.data.message || errCode['default']
    console.log('request........................');
    if(code === 401) {
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        router.push('/login');
      })
    } else if(code === 301) {
      Message({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    } else if(code === 500) {
      Message({
        message: msg,
        type: 'error'
      })
      return Promise.reject(new Error(msg))
    } else if(code !== 200) {
      Notification.error({
        title: msg,
        duration: 2 * 1000
      })
      return Promise.reject('error')
    } else {
      return res.data
    }
  }, err => {
    console.log(err);
    let { message } = err;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if(message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      let code = message.substr(message.length - 3);
      if(code === '401') {
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          router.push('/login');
        })
      } else {
        Message({
          message: message,
          type: 'error',
          duration: 2 * 1000
        })
      }
      return Promise.reject(err)
    }
    Message({
      message: message,
      type: 'error',
      duration: 2 * 1000
    })
    // return Promise.reject(err.request)
    return Promise.reject(err)
  })

  // 3.发送真正的网络请求
  return instance(config)
} 
