const TokenKey = 'token'

// 获取token
export function getToken() {
  return localStorage.getItem(TokenKey)
}

// 设置token
export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

// 移除token
export function removeToken() {
  localStorage.removeItem(TokenKey)
  // window.localStorage.removeItem('key')
}