import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/customerUser/selectList',
    method: 'get',
    params: query
  })
}

export function addCustomerUser(data) {
  return request({
    url: '/system/customerUser/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateCustomerUser(data) {
  return request({
    url: '/system/customerUser/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delCustomerUser(configId) {
  return request({
    url: '/system/customerUser/deleteByPrimaryKey?customerId=' + configId,
    method: 'post'
  })
}


export function getCustomerUser(id) {
  return request({
    url: '/system/customerUser/selectByPrimaryKey?customerId=' + id,
    method: 'post',
  })
}