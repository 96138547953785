<template>
  <!-- 系统参数配置弹窗 -->
  <el-dialog width="60%" :title="dialogTitle" :visible="propsDialogShow" @close="dialogClose()">
    <el-form label-width="180px" :model="formList" :rules="rules" ref="editForm" class="user-edit el-form--inline">
      <el-form-item label="参数代码" prop="paraCode">
        <el-input size="small" v-model="formList.paraCode" auto-complete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="参数名称" prop="paraName">
        <el-input size="small" v-model="formList.paraName" auto-complete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="参数长度" prop="codeLen">
        <el-input size="small" v-model="formList.codeLen" auto-complete="off" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="有效标志" prop="efftFlag">
        <el-select size="small" v-model="formList.efftFlag" placeholder="请选择">
          <el-option v-for="type in efftFlag" :label="type.key" :value="type.value" :key="type.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogClose">取 消</el-button>
      <el-button size="small" type="primary" @click="submitForm('editForm')">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {addPara,updatePara} from '@/network/system/systemPara'
export default {
  name: 'SystemParaCmpt',
  props: {
    // 模态窗口显示标识
    dialogShow: Boolean,
    // 外部参数传递
    transParams: Object
  },
  data() {
    return {
      // 表单title(从外部接收参数)
      dialogTitle: "",
      // 表单modal
      // omponentInfo: componentInfo(),
      //  模态窗口显示标识
      propsDialogShow: true,
      //是否编辑
      isEidt: '',
      // 数据集合
      formList: {},
      // 有效标志
      efftFlag: [
        { key: '请选择', value: ''},
        { key: '是', value: 'Y' },
        { key: '否', value: 'N' }
      ],
      // 编辑页面表单校验规则
      rules: {
        paraCode: [{ required: true, message: '请输入', trigger: 'blur' }],
        paraName: [{ required: true, message: '请输入', trigger: 'blur' }],
        codeLen: [{ required: true, message: '请输入', trigger: 'blur' }],
        efftFlag: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
    }
  },
  created () {
    this.propsDialogShow = this.dialogShow
    this.dialogTitle = this.transParams.title;
    this.formList = this.transParams.modal.formList
  },
  methods: {
    // 模态窗口提交
    submitForm(editData) {
      this.$refs[editData].validate(valid => {
        if (valid) {
          if(this.formList.defineId === undefined) {
            addPara(this.formList).then(res => {
              this.$message({
                type: 'success',
                message: '保存成功！'
              })
              this.propsDialogShow = false;
              this.$emit("dialogCloseCallback", {
                type: "submit"
              });
            })
          } else {
            updatePara(this.formList).then(res => {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.propsDialogShow = false;
              this.$emit("dialogCloseCallback", {
                type: "submit"
              });
            })
          }
        } else {
          return false
        }
      })
    },
    // 模态窗口关闭
    dialogClose () {
      this.propsDialogShow = false;
      this.$emit("dialogCloseCallback", {
        type: "cancel"
      });
    }
  },
}
</script>