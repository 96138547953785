import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/initPara/selectList',
    method: 'get',
    params: query
  })
}

export function addInitPara(data) {
  return request({
    url: '/system/initPara/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateInitPara(data) {
  return request({
    url: '/system/initPara/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delInitPara(configId) {
  return request({
    url: '/system/initPara/deleteByPrimaryKey?paraKey=' + configId,
    method: 'post'
  })
}


export function getInitPara(id) {
  return request({
    url: '/system/initPara/selectByPrimaryKey?paraKey=' + id,
    method: 'post',
  })
}