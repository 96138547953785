import request from '@/network/request'

export function goodList(query) {
  return request({
    url: '/system/productCategory/selectList',
    method: 'get',
    params: query
  })
}

export function addProductCategory(data) {
  return request({
    url: '/system/productCategory/insertSelective',
    method: 'post',
    data: data
  })
}

export function updateProductCategory(data) {
  return request({
    url: '/system/productCategory/updateByPrimaryKeySelective',
    method: 'post',
    data: data
  })
}

export function delProductCategory(configId) {
  return request({
    url: '/system/productCategory/deleteByPrimaryKey?categoryId=' + configId,
    method: 'post'
  })
}


export function getProductCategory(id) {
  return request({
    url: '/system/productCategory/selectByPrimaryKey?categoryId=' + id,
    method: 'post',
  })
}